import axios from "axios";
import {RequestProfileOption} from "../../../commons/model/download/DownloadModels";
import {MediaType} from "../../../commons/model/media/MediaSearchModels";

const findAllRequestProfileOptionsByMediaType = (mediaType?: MediaType) => axios.get<RequestProfileOption[]>(`/api/downloads/profiles`, {
    params: {
        mediaType: mediaType
    }
});

const saveDownloadProfile = (idPlexLibrary: number, idPlexLocation: number, category: string, quality: string) => axios.post(`/api/downloads/profiles/${idPlexLibrary}`, null, {
    params: {
        "idPlexLocation": idPlexLocation,
        "category": category,
        "quality": quality
    }
});
const deleteDownloadProfile = (idPlexLibrary: number) => axios.delete(`/api/downloads/profiles/${idPlexLibrary}`);

const DownloadProfileService = {
    findAllRequestProfileOptionsByMediaType,
    saveDownloadProfile,
    deleteDownloadProfile
}

export default DownloadProfileService;