import axios from "axios";
import {MediaRelease} from "../../../commons/model/media/MediaReleaseModels";

const findAllMovieReleases = (idTmdb: number, validTitle: boolean) =>
    axios.get<MediaRelease[]>(`/api/releases/movies/${idTmdb}`, {
        params: {
            validTitle: validTitle
        }
    });

const findAllTvSeasonReleases = (idTmdb: number, season: number, validTitle: boolean) =>
    axios.get<MediaRelease[]>(`/api/releases/tvs/${idTmdb}/seasons/${season}`, {
        params: {
            validTitle: validTitle
        }
    });

const findAllTvEpisodeReleases = (idTmdb: number, season: number, episode: number, validTitle: boolean) =>
    axios.get<MediaRelease[]>(`/api/releases/tvs/${idTmdb}/seasons/${season}/episodes/${episode}`, {
        params: {
            validTitle: validTitle
        }
    });

const downloadMovie = (idTmdb: number, idPlexLibrary: number, releaseUrl: string) =>
    axios.post(`/api/downloads/tmdb/movies/${idTmdb}`, null, {
        params: {
            idPlexLibrary: idPlexLibrary,
            releaseUrl: releaseUrl
        }
    });

const downloadTvSeason = (idTmdb: number, season: number, idPlexLibrary: number, releaseUrl: string) =>
    axios.post(`/api/downloads/tmdb/tvs/${idTmdb}/seasons/${season}`, null, {
        params: {
            idPlexLibrary: idPlexLibrary,
            releaseUrl: releaseUrl
        }
    });

const downloadTvEpisode = (idTmdb: number, season: number, episode: number, idPlexLibrary: number, releaseUrl: string) =>
    axios.post(`/api/downloads/tmdb/tvs/${idTmdb}/seasons/${season}/episodes/${episode}`, null, {
        params: {
            idPlexLibrary: idPlexLibrary,
            releaseUrl: releaseUrl
        }
    });

const DownloadReleaseService = {
    findAllMovieReleases,
    findAllTvSeasonReleases,
    findAllTvEpisodeReleases,
    downloadMovie,
    downloadTvSeason,
    downloadTvEpisode
}

export default DownloadReleaseService;