import {Box, Button} from "@mui/material";
import {MovieDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MovieHeader from "./MovieHeader";
import React, {useState} from "react";
import MediaMovieCollectionCard from "../../../core/media/card/MediaMovieCollectionCard";
import MediaSearchResultCardSlider from "../../../core/media/slider/MediaSearchResultCardSlider";
import MovieAvailabilityDetail from "./MovieAvailabilityDetail";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MovieRelease from "./MovieRelease";
import AlternateTitleInput from "../../../download/alternate_title/AlternateTitleInput";

type mode = 'CAST' | 'RELEASE';

export interface MovieDetailContainerProps {
    movie: MovieDetail
}

export default function MovieDetailContainer({movie}: MovieDetailContainerProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [mode, setMode] = useState<mode>('CAST');

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleCastReturn = () => {
        setMode("CAST");
    }

    const handleReleases = () => {
        setMode("RELEASE");
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    let navigation;
    switch (mode) {
        case "CAST":
            navigation = <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Button variant={"outlined"} startIcon={<CloudDownloadIcon/>} onClick={handleReleases}>Voir les
                    releases</Button>
                <AlternateTitleInput media={movie}/>
            </Box>;
            break;
        case "RELEASE":
            navigation = <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Button variant={"outlined"} startIcon={<ArrowBackIcon/>} onClick={handleCastReturn}>Retour au détail du
                    film</Button>
            </Box>;
            break;
        default:
            navigation = <Box/>;
    }

    let detail;
    switch (mode) {
        case "CAST":
            detail = <MediaSearchResultCardSlider title={'Casting'} data={movie.casts.cast}/>;
            break;
        case "RELEASE":
            detail = <MovieRelease onReturnToDetailAsked={handleCastReturn} movie={movie}/>;
            break;
        default:
            detail = <Box/>;
    }

    return <Box>
        <MovieHeader movie={movie}/>
        {movie?.collection && <MediaMovieCollectionCard collection={movie.collection}/>}
        <MovieAvailabilityDetail movie={movie}/>
        <Box margin={2}>
            {navigation}
            <Box margin={2}/>
            {detail}
        </Box>
    </Box>
}