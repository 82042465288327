import axios from "axios";
import {MediaPageSize, PaginatedResult} from "../../../commons/model/pagination/PaginationModels";
import {SearchResult} from "../../../commons/model/media/MediaSearchModels";
import {MediaSort, MediaProvider} from "../../../commons/model/media/MediaCoreModels";

const trendingAllByDay = (pageSize: MediaPageSize, page: number) => axios.get<PaginatedResult<SearchResult>>(`/api/medias/discover/trending/all/day`, {
    params: {
        pageSize: pageSize,
        page: page
    }
});
const popularMovies = (pageSize: MediaPageSize, page: number) => axios.get<PaginatedResult<SearchResult>>(`/api/medias/discover/movies/popular`, {
    params: {
        pageSize: pageSize,
        page: page
    }
});
const upcomingMovies = (pageSize: MediaPageSize, page: number) => axios.get<PaginatedResult<SearchResult>>(`/api/medias/discover/movies/upcoming`, {
    params: {
        pageSize: pageSize,
        page: page
    }
});
const requestedMovies = () => axios.get<SearchResult[]>(`/api/medias/discover/movies/requested`);
const providerMovies = (pageSize: MediaPageSize, page: number, provider: MediaProvider, sort: MediaSort) => axios.get<PaginatedResult<SearchResult>>(`/api/medias/discover/movies/provider`, {
    params: {
        pageSize: pageSize,
        page: page,
        provider: provider,
        sort: sort
    }
});
const providerTvs = (pageSize: MediaPageSize, page: number, provider: MediaProvider, sort: MediaSort) => axios.get<PaginatedResult<SearchResult>>(`/api/medias/discover/tvs/provider`, {
    params: {
        pageSize: pageSize,
        page: page,
        provider: provider,
        sort: sort
    }
});

const popularTvs = (pageSize: MediaPageSize, page: number) => axios.get<PaginatedResult<SearchResult>>(`/api/medias/discover/tvs/popular`, {
    params: {
        pageSize: pageSize,
        page: page
    }
});
const upcomingTvs = (pageSize: MediaPageSize, page: number) => axios.get<PaginatedResult<SearchResult>>(`/api/medias/discover/tvs/upcoming`, {
    params: {
        pageSize: pageSize,
        page: page
    }
});
const lastUpdatedTvs = () => axios.get<SearchResult[]>(`/api/medias/discover/tvs/last-updated`);

const MediaDiscoverService = {
    trendingAllByDay,
    popularMovies,
    upcomingMovies,
    requestedMovies,
    providerMovies,
    providerTvs,
    popularTvs,
    upcomingTvs,
    lastUpdatedTvs
}

export default MediaDiscoverService;