import {Box} from "@mui/material";
import {YoutubeDiscoverChannel} from "../../../../../domain/commons/model/youtube/YoutubeModels";
import YoutubeChannelCard from "../card/YoutubeChannelCard";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import Title from "../../title/Title";
import React from "react";


export interface YoutubeChannelCardSliderProps {
    title: string,
    link?: string,
    data: YoutubeDiscoverChannel[]
}

export default function YoutubeChannelCardSlider({title, link, data}: YoutubeChannelCardSliderProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasData = data !== undefined && data.length > 0;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (hasData) {

        return <Box>
            <Title label={title} link={link}/>
            <Box sx={{display: 'flex', flexWrap: "nowrap", overflowX: "auto"}}>
                {data.map((item) => (
                    <YoutubeChannelCard data={item} key={uuidv4()}/>
                ))}
            </Box>
        </Box>
    } else {

        return <Box/>
    }
}