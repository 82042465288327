import {Box, Typography} from "@mui/material";
import {format} from 'date-fns'
import {fr} from 'date-fns/locale'
import {PersonDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import BaseHeader from "../core/header/BaseHeader";

export interface PersonHeaderProps {
    person: PersonDetail,
    backdropPath?: string
}

export default function PersonHeader({person, backdropPath}: PersonHeaderProps) {


    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    let quickBioTxt;
    if (person.birthDay !== undefined && person.deathDay === undefined) {

        quickBioTxt = 'Né(e) le ' + format(new Date(person.birthDay), 'dd MMMM yyyy', {locale: fr});
    } else if (person.deathDay !== undefined) {
        quickBioTxt = format(new Date(person.birthDay), 'dd MMMM yyyy', {locale: fr}) + ' - ' + format(new Date(person.deathDay), 'dd MMMM yyyy', {locale: fr});
    }

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const headerContent = <Box margin={2}>
        <Box display={"flex"} gap={2}>
            <Typography variant="h4" style={{fontWeight: 'bold'}}>{person.name}</Typography>
        </Box>
        <Box display={"flex"} gap={2}>
            {quickBioTxt && <Typography>{quickBioTxt}</Typography>}
            {(quickBioTxt !== undefined && person.placeOfBirth !== undefined) && <Typography>|</Typography>}
            {person.placeOfBirth && <Typography>{person.placeOfBirth}</Typography>}
        </Box>
        <Box marginTop={2}/>
        {person.biography && <Typography>{person.biography}</Typography>}
    </Box>

    return <BaseHeader headerContent={headerContent} posterImage={person.imagePath} backdropImage={backdropPath}/>
}