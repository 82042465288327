import * as React from "react";
import {Box} from "@mui/material";
import {MediaResult, sortMediasByYear} from "../../../../../domain/commons/model/media/MediaSearchModels";
import MediaSearchResultCardContainer from "./MediaSearchResultCardContainer";


export interface SortedMediaResultCardContainerProps {
    data: MediaResult[],
    title: string,
    reverse?: boolean
}

export default function SortedMediaResultCardContainer({data, title, reverse}: SortedMediaResultCardContainerProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (data) {
        return <MediaSearchResultCardContainer data={sortMediasByYear(data, reverse)} title={title}/>
    } else {
        return <Box/>
    }
}