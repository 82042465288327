import {useEffect, useRef, useState} from "react";
import {Card, CardActionArea, CardContent, CardMedia} from "@mui/material";
import {Link} from "react-router-dom";
import {Constants} from "../../../../../domain/commons/constants/Constants";
import {MediaType} from "../../../../../domain/commons/model/media/MediaSearchModels";
import MediaTypeChip from "../chip/MediaTypeChip";
import MediaDownloadStatusChip from "../chip/MediaDownloadStatusChip";
import AvailabilityStatusChip from "../chip/AvailabilityStatusChip";
import {DownloadRequestStatus, MediaAvailability} from "../../../../../domain/commons/model/media/MediaCoreModels";

export interface MediaBasicCardProps {
    hoverContent: JSX.Element,
    posterImage?: string,
    link: string,
    mediaType?: MediaType,
    showMediaType?: boolean,
    availability?: MediaAvailability,
    requestStatus?: DownloadRequestStatus,
}

export default function MediaBasicCard({
                                           hoverContent,
                                           posterImage,
                                           link,
                                           mediaType,
                                           showMediaType,
                                           availability,
                                           requestStatus
                                       }: MediaBasicCardProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const componentRef = useRef<HTMLDivElement | null>(null);
    const [showDetail, setShowDetail] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const finalPosterImage = posterImage !== undefined ? Constants.assets.posterBaseUrl + posterImage : Constants.assets.noPosterUrl;
    const displayMediaType = showMediaType === undefined || showMediaType;
    const displayMediaDownloadStatus = availability !== undefined || requestStatus !== undefined;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onMouseEnter() {
        setShowDetail(true);
    }

    function onMouseLeave() {
        setShowDetail(false);
    }

    function onClickOutside(event: MouseEvent) {
        if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
            setShowDetail(false);
        }
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);

        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, []);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

        // prepare content

    let cardContent;
    if (showDetail) {
        cardContent = <Link to={link}>
            <CardContent
                sx={{
                    position: 'absolute', bottom: 0, left: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', color: '#fff', width: '100%', height: '100%'
                }}
            >
                {hoverContent}
            </CardContent>
        </Link>
    }

    // render

    return <Card ref={componentRef}
                 sx={{borderRadius: '12px', width: 176, margin: 1, flexShrink: 0, border: '1px solid #313A47'}}
                 className={"mediaCard"}
                 onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <CardActionArea>
            <CardMedia component="img" image={finalPosterImage}/>
            {(displayMediaType && mediaType) && <MediaTypeChip type={mediaType}/>}
            {displayMediaDownloadStatus &&
                <MediaDownloadStatusChip chipType={"icon"} mediaType={mediaType} availability={availability} requestStatus={requestStatus}/>}
            {availability && <AvailabilityStatusChip chipType={"icon"} availability={availability}/>}
            {cardContent}
        </CardActionArea>
    </Card>
}