import {Card, Chip, IconButton, Tooltip} from "@mui/material";
import {MediaRelease} from "../../../../../../domain/commons/model/media/MediaReleaseModels";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MediaReleaseDateChip from "../../chip/MediaReleaseDateChip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Link} from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";

export interface MediaReleaseCardProps {
    data: MediaRelease
    onDownloadClick?: (release: MediaRelease) => void
}

export default function MediaReleaseCard({data, onDownloadClick}: MediaReleaseCardProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/


    return <Card>
        <Box sx={{
            height: 50,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 1,
            justifyContent: "space-between",
            position: 'relative'
        }}>
            <Typography>{data.name}</Typography>
            <MediaReleaseDateChip releaseDate={data.uploadedAt}/>
        </Box>
        <Box sx={{
            height: 50,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 1,
            gap: 1,
            position: 'relative'
        }}>
            <Chip label={data.filesizeString}/>

            {onDownloadClick && <IconButton onClick={() => onDownloadClick(data)} sx={{position: 'absolute', bottom: 10, right: 50}}>
                <DownloadIcon/>
            </IconButton>}

            <Link to={data.url} target="_blank" rel="noopener noreferrer">
                <Tooltip title="Suivre le lien de la release" placement={"left"}>
                    <IconButton sx={{position: 'absolute', bottom: 10, right: 10}}>
                        <VisibilityIcon/>
                    </IconButton>
                </Tooltip>
            </Link>

        </Box>
    </Card>
}