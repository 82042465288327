import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {PersonDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaPersonService from "../../../../../domain/service/media/detail/person/MediaPersonService";
import {sortMediasByYear} from "../../../../../domain/commons/model/media/MediaSearchModels";
import MediaHeaderSkeleton from "../../../../component/media/detail/core/header/skeleton/MediaHeaderSkeleton";
import LoadingAlert from "../../../../component/core/alert/LoadingAlert";
import PersonDetailContainer from "../../../../component/media/detail/person/PersonDetailContainer";

export default function MediaPersonDetailPage() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [person, setPerson] = useState<PersonDetail>();
    const [mostRecentBackdropPath, setMostRecentBackdropPath] = useState<string | undefined>('');

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const mediaPersonService = MediaPersonService;
    const {personId} = useParams();

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchCollection = useCallback(async () => {

        const id = personId ? +personId : 0;
        setLoading(true);
        mediaPersonService.fetchOne(id)
            .then((response => {
                setShowServerError(false);
                setPerson(response.data);
                updateMostRecentBackdrop(response.data);
            }))
            .catch(onServerError)
            .finally(() => setLoading(false));

    }, [personId, mediaPersonService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function updateMostRecentBackdrop(person: PersonDetail) {

        const result = sortMediasByYear(person.credits.cast, true)
            .filter(media => media.backdropPath !== undefined);

        if (result.length > 0) {
            setMostRecentBackdropPath(result[0].backdropPath);
        }
    }

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchCollection();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchCollection();
    }, [fetchCollection]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (loading) {
        return <MediaHeaderSkeleton/>
    } else if (showServerError || person === undefined) {
        return <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        return <PersonDetailContainer person={person} backdropPath={mostRecentBackdropPath}/>
    }
}