import {Box} from "@mui/material";
import {YoutubeDiscoverVideo} from "../../../../../domain/commons/model/youtube/YoutubeModels";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import Title from "../../title/Title";
import YoutubeVideoCard from "../card/YoutubeVideoCard";

export interface YoutubeVideoCardContainerProps {
    title: string,
    data: YoutubeDiscoverVideo[]
}

export default function YoutubeVideoCardContainer({title, data}: YoutubeVideoCardContainerProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasData = data !== undefined && data.length > 0;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (hasData) {

        return <Box>
            <Title label={title}/>
            <Box sx={{display: 'flex', flexWrap: "wrap"}}>
                {data.map((item) => (
                    <YoutubeVideoCard video={item} key={uuidv4()}/>
                ))}
            </Box>
        </Box>
    } else {

        return <Box/>
    }
}