import React, {useRef, useState} from 'react';
import {Box, CssBaseline, ThemeProvider, Toolbar} from "@mui/material";
import {appTheme} from "./theme";
import AppRoutes from "./AppRoutes";
import SidebarMenu from "./application/component/core/menu/SidebarMenu";
import AppToolbar from "./application/component/core/menu/AppToolbar";
import LoginPage from "./application/pages/login/LoginPage";
import User from "./domain/commons/model/user/User";
import PlexSettingPage from "./application/pages/plex/setting/PlexSettingPage";

export default function App() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [user, setUser] = useState<User | undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [isPlexConfigured, setIsPlexConfigured] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const boxRef = useRef();

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const isConfigured = (): boolean => {

        return isLogged && isPlexConfigured;
    }

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleScroll = () => {

        const scrollPosition = document.getElementById('mainBox')?.scrollTop;
        setIsScrolled(scrollPosition ? (scrollPosition > 0) : false);
    };

    const handleClick = () => {

        setIsOpen(!isOpen);
    }

    const onLoginSuccess = (user: User) => {
        setUser(user);
        setIsLogged(true);
    }

    const onSettingSuccess = () => {
        setIsPlexConfigured(true);
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <ThemeProvider theme={appTheme}>
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            {isConfigured() && <SidebarMenu open={isOpen} setOpen={setIsOpen}/>}
            <Box ref={boxRef} id={'mainBox'} onScroll={handleScroll} component="main"
                 sx={{flexGrow: 1, height: '100vh', overflow: 'auto'}}>

                {isConfigured() &&
                    <AppToolbar isScrolled={isScrolled} onClick={handleClick} user={user}/>}
                {isConfigured() && <Toolbar/>}
                {isConfigured() && <AppRoutes/>}
                {(!isConfigured() && !isLogged) && <LoginPage onLoginSuccess={onLoginSuccess}/>}
                {(!isConfigured() && isLogged) && <PlexSettingPage onSettingSuccess={onSettingSuccess}/>}
            </Box>
        </Box>
    </ThemeProvider>;
}