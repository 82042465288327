import {Box, Skeleton} from "@mui/material";
import {uuidv4} from "../../../../../../domain/commons/utils/UUIDProvider";

export default function AlternativeTitleCardContainerSkeleton() {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/


    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
        {Array.from({length: 10}).map(() => (
            <Skeleton variant={"rectangular"} width={"100%"} height={100} key={uuidv4()}/>
        ))}
    </Box>
}