import React from "react";
import {Box, IconButton, Toolbar} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {SxProps} from "@mui/system/styleFunctionSx";
import {useMQ} from "../../../../theme";
import {Property} from "csstype";
import User from "../../../../domain/commons/model/user/User";
import UserAvatar from "../user/UserAvatar";
import SearchInput from "../search/SearchInput";

export interface AppToolbarProps {
    isScrolled: boolean,
    onClick: () => void,
    user?: User
}

export default function AppToolbar({
                                       isScrolled,
                                       onClick,
                                       user
                                   }: AppToolbarProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const isPermanentAppMenu = useMQ().isPermanentAppMenu;

    const searchWidth: Property.Width<any> = isPermanentAppMenu ? 'calc(100% - 300px)' : 'calc(100% - 80px)';

    const baseSx: SxProps = {
        zIndex: 2,
        width: '100%',
        WebkitTransition: 'background-color 0.5s ease-out, background 0.3s ease-out',
        MozTransition: 'background-color 0.5s ease-out, background 0.3s ease-out',
        'transition': 'background-color 0.5s ease-out, background 0.5s ease-out'
    }

    const dynamicSx: Partial<SxProps> = isScrolled ? {
        backgroundColor: 'rgba(55, 65, 81, 0.5)',
        backdropFilter: 'blur(5px)'
    } : {
        background: 'transparent',
        boxShadow: 'none'
    };

    const toolbarSx = {
        ...baseSx,
        ...dynamicSx
    }

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box position={'fixed'} sx={toolbarSx}>
        <Toolbar>
            {isPermanentAppMenu ? undefined : <IconButton onClick={onClick} sx={{marginRight: 3}}>
                <MenuIcon/>
            </IconButton>}
            <SearchInput width={searchWidth}/>
            <UserAvatar user={user}/>
        </Toolbar>
    </Box>
}
