import * as React from "react";
import {Box, FormControlLabel, InputLabel, Radio, RadioGroup} from "@mui/material";
import {PlexLocation} from "../../../../domain/commons/model/plex/PlexModels";
import {uuidv4} from "../../../../domain/commons/utils/UUIDProvider";

export interface PlexLocationSelectorProps {
    label: string
    values: PlexLocation[]
    selected?: PlexLocation
    setSelected: (value: PlexLocation) => void
}

export default function PlexLocationSelector({label, values, selected, setSelected}: PlexLocationSelectorProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleChange = (value: string) => {
        const idPlexLocation: number = +value;
        let selected = values.find((value) => value.idPlexLocation === idPlexLocation);
        if(selected) setSelected(selected);
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <InputLabel variant="standard">
            {label}
        </InputLabel>
        <RadioGroup onChange={(event, value) => handleChange(value)}>
            {values.map((value) =>
                <FormControlLabel key={uuidv4()} control={<Radio checked={selected?.idPlexLocation === value.idPlexLocation}/>} label={value.path} value={value.idPlexLocation}/>
            )}
        </RadioGroup>
    </Box>
}