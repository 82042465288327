import {Box} from "@mui/material";
import {MovieResult, PersonResult, SearchResult, TvResult} from "../../../../../domain/commons/model/media/MediaSearchModels";
import MediaMovieCard from "./MediaMovieCard";
import MediaTvCard from "./MediaTvCard";
import MediaPersonCard from "./MediaPersonCard";

export interface MediaSearchResultCardProps {
    data: SearchResult
}

export default function MediaSearchResultCard({data}: MediaSearchResultCardProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (data.mediaType === 'MOVIE') {
        return <MediaMovieCard data={data as MovieResult}/>
    } else if (data.mediaType === 'TV') {
        return <MediaTvCard data={data as TvResult}/>
    } else if (data.mediaType === 'PERSON') {
        return <MediaPersonCard data={data as PersonResult}/>
    } else {
        return <Box/>
    }
}