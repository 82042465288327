import {InputAdornment, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export interface SearchInputProps {
    width: number
}

export default function SearchInput({width}: SearchInputProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [previousLocation, setPreviousLocation] = useState('');
    const [queryUrl, setQueryUrl] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [localSearchQuery, setLocalSearchQuery] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const [searchParams] = useSearchParams();
    const pathQuery = searchParams.get("query");

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleSearchInputChange = (change: string) => {
        setLocalSearchQuery(change);
    };

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            const encodedQuery = `${queryUrl}?query=${encodeURIComponent(localSearchQuery)}`;
            navigate(encodedQuery);
        }
    }

    function handleClear() {
        setLocalSearchQuery('');
        navigate('/');
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        if (location.pathname.startsWith('/youtube')) {
            setPlaceholder('Rechercher une chaine Youtube');
            setQueryUrl('/youtube/search/channels');
        } else {
            setPlaceholder('Rechercher Films & Séries');
            setQueryUrl('/medias/search');
        }

        if (location.pathname !== queryUrl && location.pathname !== previousLocation) {
            setLocalSearchQuery('');
        } else if (localSearchQuery === '' && pathQuery) {
            setLocalSearchQuery(pathQuery);
        }
        setPreviousLocation(location.pathname);
    }, [location, setLocalSearchQuery, localSearchQuery, pathQuery, queryUrl, previousLocation]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <TextField
        onChange={event => handleSearchInputChange(event.target.value)}
        onKeyDown={handleKeyDown}
        variant="outlined"
        size={'small'}
        sx={{width: width}}
        value={localSearchQuery}
        label={placeholder}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    {localSearchQuery && <IconButton onClick={handleClear}>
                        <CancelOutlinedIcon/>
                    </IconButton>}

                </InputAdornment>
            ),
        }}
    />

}