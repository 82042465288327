import {Box, Typography} from "@mui/material";
import MediaBasicCard from "./MediaBasicCard";
import {TvResult} from "../../../../../domain/commons/model/media/MediaSearchModels";

export interface MediaTvCardProps {
    data: TvResult
}

export default function MediaTvCard({data}: MediaTvCardProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const detailLink = '/medias/detail/tvs/' + data.id;
    const posterImage = data.imagePath;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const hoverContent = <Box sx={{marginTop: 10}}>
        {data.year && <Typography fontSize={14}>{data.year}</Typography>}
        <Typography className={"mediaCardTitle"} fontSize={20} fontWeight={900}
                    lineHeight={1}>{data.title}</Typography>
        {data.character && <Typography fontSize={14}>{data.character}</Typography>}
    </Box>

    return <MediaBasicCard hoverContent={hoverContent} posterImage={posterImage} link={detailLink}
                           mediaType={data.mediaType} availability={data.availability} requestStatus={data.requestStatus}/>
}