import * as React from "react";
import {useCallback, useState} from "react";
import {PlexLibrary, PlexLocation} from "../../../../domain/commons/model/plex/PlexModels";
import {Box, Button, Drawer} from "@mui/material";
import ToggleSelector, {ToggleSelectorOption} from "../../core/input/ToggleSelector";
import Title from "../../core/title/Title";
import PlexLocationSelector from "./PlexLocationSelector";
import SaveIcon from '@mui/icons-material/Save';
import DownloadProfileService from "../../../../domain/service/download/profile/DownloadProfileService";

export interface PlexLibrarySettingDrawerProps {
    open: boolean
    library: PlexLibrary,
    width?: number,
    onClose: () => void
}

export default function PlexLibrarySettingDrawer({open, onClose, library}: PlexLibrarySettingDrawerProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    let categoryOptions: ToggleSelectorOption[];
    switch (library.mediaType) {
        case "MOVIE":
            categoryOptions = [
                {value: "VIDEO_FILM", label: "Film"},
                {value: "VIDEO_ANIMATION", label: "Animation"}
            ];
            break;
        case "TV":
            categoryOptions = [
                {value: "VIDEO_SERIE", label: "Série TV"},
                {value: "VIDEO_ANIMATION_SERIE", label: "Série Animation"}
            ];
            break;
        default:
            categoryOptions = [{value: "UNKNOWN", label: "UNKNOWN"}];
    }

    const qualityOptions: ToggleSelectorOption[] = [
        {value: "FULL_HD_1080", label: "1080P"},
        {value: "ULTRA_HD_4K", label: "4K"}
    ]

    const defaultCategory = library.downloadProfile?.category ? library.downloadProfile.category : categoryOptions[0].value;
    const defaultQuality = library.downloadProfile?.quality ? library.downloadProfile.quality : qualityOptions[0].value;
    const defaultLocation = library.downloadProfile?.idPlexLocation ? library.locations.find((location) => location.idPlexLocation === library.downloadProfile?.idPlexLocation) : undefined;

    const [category, setCategory] = useState(defaultCategory);
    const [quality, setQuality] = useState(defaultQuality);
    const [location, setLocation] = useState<PlexLocation | undefined>(defaultLocation);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const valid = category !== undefined && quality !== undefined && location !== undefined;

    const downloadProfileService = DownloadProfileService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const saveProfil = useCallback(async () => {
        if (location?.idPlexLocation !== undefined && category !== undefined && quality !== undefined) {
            const result = await downloadProfileService.saveDownloadProfile(library.idPlexLibrary, location?.idPlexLocation, category, quality);
            if (result.status === 200) {
                onClose();
            }
        }

    }, [downloadProfileService, library.idPlexLibrary, onClose, category, quality, location?.idPlexLocation]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleSave = () => {

        saveProfil();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Drawer anchor={"right"} open={open} onClose={onClose}>
        <Box minWidth={350} maxWidth={350} padding={2} display={"flex"} flexDirection={"column"} gap={1}>
            <Title label={library.title}/>
            <ToggleSelector label="Catégorie" values={categoryOptions} selected={category} setSelected={setCategory}/>
            <ToggleSelector label="Qualité" values={qualityOptions} selected={quality} setSelected={setQuality}/>
            <PlexLocationSelector label="Destination" values={library.locations} selected={location}
                                  setSelected={setLocation}/>
            <Button disabled={!valid} variant="contained" startIcon={<SaveIcon/>} onClick={handleSave}>
                Sauvegarder
            </Button>
        </Box>

    </Drawer>
}