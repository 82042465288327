import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputLabel,
    Radio,
    RadioGroup,
    Skeleton
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import React, {useCallback, useEffect, useState} from "react";
import LoadingAlert from "../../../core/alert/LoadingAlert";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import {RequestProfileOption} from "../../../../../domain/commons/model/download/DownloadModels";
import DownloadProfileService from "../../../../../domain/service/download/profile/DownloadProfileService";
import {MediaType} from "../../../../../domain/commons/model/media/MediaSearchModels";

export interface MediaProfileSelectorDialogProps {
    title: string
    mediaType: MediaType
    open: boolean
    onClose: () => void
    onDownloadProfileSelected: (idQualityProfile: number) => void
}

export default function MediaProfileSelectorDialog({
                                               title,
                                               mediaType,
                                               open,
                                               onClose,
                                               onDownloadProfileSelected
                                           }: MediaProfileSelectorDialogProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [selectedProfile, setSelectedProfile] = useState<number | undefined>();
    const [profiles, setProfiles] = useState<RequestProfileOption[]>();
    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const downloadProfileService = DownloadProfileService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchDownloadProfiles = useCallback(async () => {

        if(open) {
            setLoading(true);
            downloadProfileService.findAllRequestProfileOptionsByMediaType(mediaType)
                .then((response => {
                    setShowServerError(false);
                    setProfiles(response.data);
                }))
                .catch(onServerError)
                .finally(() => setLoading(false));
        }

    }, [downloadProfileService, mediaType, open]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchDownloadProfiles();
    }

    const handleChange = (idPlexLibraryStr: string) => {
        const idPlexLibrary: number = +idPlexLibraryStr;
        setSelectedProfile(idPlexLibrary);
    }

    const handleValidate = () => {

        if (selectedProfile) {
            onDownloadProfileSelected(selectedProfile);
            onClose();
        }
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchDownloadProfiles();
    }, [fetchDownloadProfiles]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const profileOptions = profiles !== undefined ? profiles : [];

    let content;
    if (loading) {
        content = <DialogContent>
            <Skeleton variant={"rectangular"} width={110} height={20} sx={{marginBottom: 1}}/>
            <Skeleton variant={"rectangular"} width={400} height={50}/>
        </DialogContent>
    } else if (showServerError || profiles === undefined) {
        content = <DialogContent>
            <LoadingAlert onClick={handleReload} width={"100%"}/>
        </DialogContent>
    } else {
        content = <DialogContent>
            <InputLabel variant="standard">
                Profil de qualité
            </InputLabel>
            <RadioGroup onChange={(event) => handleChange(event.target.value)}>
                {profileOptions.map((profile) => <FormControlLabel key={uuidv4()} value={profile.idPlexLibrary}
                                                                   control={<Radio/>}
                                                                   label={profile.title}/>)}
            </RadioGroup>
        </DialogContent>
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        {content}
        <DialogActions>
            <Button onClick={onClose} variant={"outlined"}>Annuler</Button>
            <Button disabled={loading || selectedProfile === undefined} onClick={handleValidate} variant={"contained"}
                    autoFocus
                    startIcon={<DownloadIcon/>}>Demander</Button>
        </DialogActions>
    </Dialog>
}