import {Box} from "@mui/material";
import PersonFilmographyContainer from "./PersonFilmographyContainer";
import {PersonDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import React from "react";
import PersonHeader from "./PersonHeader";

export interface PersonDetailContainerProps {
    person: PersonDetail,
    backdropPath?: string
}

export default function PersonDetailContainer({person, backdropPath}: PersonDetailContainerProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <PersonHeader person={person} backdropPath={backdropPath}/>
        <Box margin={2}>
            <PersonFilmographyContainer data={person.credits.cast}/>
        </Box>
    </Box>
}