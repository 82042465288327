import {Card, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {AlternateTitle} from "../../../../../../domain/commons/model/download/DownloadModels";
import DeleteIcon from "@mui/icons-material/Delete";

export interface AlternateTitleCardProps {
    data: AlternateTitle,
    onDelete: (id: string) => void
}

export default function AlternateTitleCard({data, onDelete}: AlternateTitleCardProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/


    return <Card>
        <Box sx={{
            height: 50,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 1,
            justifyContent: "space-between",
            position: 'relative'
        }}>
            <Typography>{data.title}</Typography>
            <IconButton onClick={() => onDelete(data.id)}>
                <DeleteIcon />
            </IconButton>
        </Box>
    </Card>
}