import * as React from "react";
import {useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SettingLibrary from "./SettingLibrary";

export default function SettingPage() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [tab, setTab] = useState(0);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box sx={{margin: 2}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Bibliothèques Plex" />
            </Tabs>
        </Box>
        <Box>
            {tab === 0 && <SettingLibrary/> }
        </Box>
    </Box>
}