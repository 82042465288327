import {Box, Typography} from "@mui/material";
import {Genre} from "../../../../../domain/commons/model/media/MediaCoreModels";

export interface MediaGenresProps {
    genres?: Genre[]
}

export default function MediaGenres({genres}: MediaGenresProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const joinedGenres = genres !== undefined && genres.map(genre => genre.name).join(", ");

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (genres) {
        return <Typography>{joinedGenres}</Typography>
    } else {

        return <Box/>
    }
}