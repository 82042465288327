import {Box} from "@mui/material";

export interface YoutubeChannelHeaderBackdropProps {
    backdropPath?: string
}

export default function YoutubeChannelHeaderBackdrop({backdropPath}: YoutubeChannelHeaderBackdropProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const finalBackdropImage = backdropPath !== undefined ? backdropPath + '=w2560-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj' : undefined;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (finalBackdropImage) {
        const backdropUrl = "linear-gradient(rgba(17, 24, 39, 0.47) 0%, rgb(17, 24, 39) 100%), url(" + finalBackdropImage + ")";

        return <Box style={{
            height: 450,
            backgroundImage: backdropUrl,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: -1
        }}/>
    } else {
        return <Box/>
    }
}