import {Box} from "@mui/material";
import YoutubeChannelSkeletonCard from "../card/skeleton/YoutubeChannelSkeletonCard";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import Title from "../../title/Title";
import React from "react";

export interface YoutubeChannelSkeletonCardSliderProps {
    title: string,
    link?: string
}

export default function YoutubeChannelSkeletonCardSlider({title, link}: YoutubeChannelSkeletonCardSliderProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <Title label={title} link={link}/>
        <Box sx={{display: 'flex', flexWrap: "nowrap", overflowX: "auto"}}>
            {Array.from({length: 10}).map(() => (
                <YoutubeChannelSkeletonCard key={uuidv4()}/>
            ))}
        </Box>
    </Box>
}