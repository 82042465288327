import {Card, Skeleton} from "@mui/material";
import {uuidv4} from "../../../../../../domain/commons/utils/UUIDProvider";

export default function MediaSkeletonCard() {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Card sx={{maxWidth: 176, margin: 1, borderRadius: '12px'}} key={uuidv4()}>
        <Skeleton variant="rectangular" width={176} height={263}/>
    </Card>
}