import {Box, Skeleton} from "@mui/material";
import Title from "../../../title/Title";
import {uuidv4} from "../../../../../../domain/commons/utils/UUIDProvider";

export interface MediaReleaseCardContainerProps {
    title: string
}

export default function MediaSkeletonReleaseCardContainer({title}: MediaReleaseCardContainerProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/


    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <Title label={title}/>
        <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
            {Array.from({length: 10}).map(() => (
                <Skeleton variant={"rectangular"} width={"100%"} height={100} key={uuidv4()}/>
            ))}
        </Box>
    </Box>
}