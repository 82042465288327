import * as React from "react";
import {MediaResult} from "../../../../../domain/commons/model/media/MediaSearchModels";
import SortedMediaResultCardContainer from "../../../core/media/container/SortedMediaResultCardContainer";


export interface PersonFilmographyContainerProps {
    data: MediaResult[]
}

export default function PersonFilmographyContainer({data}: PersonFilmographyContainerProps) {

    const sliderTitle = "Filmographie";

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function filteredData() {

        return data.filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    (m) => m.id === value.id
                )
        )
    }

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <SortedMediaResultCardContainer title={sliderTitle} data={filteredData()} reverse={true}/>
}