import {Avatar, Box, Button} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import PlexOAuth from "../../../domain/commons/utils/plex";
import axios from "axios";
import User from "../../../domain/commons/model/user/User";
import {Constants} from "../../../domain/commons/constants/Constants";
import UserService from "../../../domain/service/user/UserService";

export interface LoginPageProps {
    onLoginSuccess: (user: User) => void
}

export default function LoginPage({onLoginSuccess}: LoginPageProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [init, setInit] = useState(true);
    const [loading, setLoading] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const userService = UserService;
    const plexOAuth = new PlexOAuth();
    let loginTimeout: NodeJS.Timeout | undefined = undefined;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const validateAuthToken = useCallback(async (authToken: string) => {
        try {
            const authResult = await axios.post("/auth/plex", null, {
                params: {
                    "plexToken": authToken
                }
            });
            if (authResult.status === 200) {
                const userResponse = await userService.fetchMe();
                localStorage.setItem(Constants.localStorage.plexToken, authToken);
                onLoginSuccess(userResponse.data);
            }
        } catch (e) {
            localStorage.removeItem(Constants.localStorage.plexToken);
        } finally {
            setLoading(false);
            setInit(false);
        }
    }, [userService, onLoginSuccess]);

    const getPlexLogin = async () => {
        try {
            const authToken = await plexOAuth.login();
            validateAuthToken(authToken);
        } catch (e) {
            setLoading(false);
        } finally {
            clearTimeout(loginTimeout);
        }
    };

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onLoginClick() {
        setLoading(true);
        plexOAuth.preparePopup();
        loginTimeout = setTimeout(() => getPlexLogin(), 1500);
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        const localPlexToken = localStorage.getItem(Constants.localStorage.plexToken);
        if (localPlexToken !== null) {
            setLoading(true);
            validateAuthToken(localPlexToken);
        } else {
            setInit(false);
        }
    }, [validateAuthToken]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }}>
        {
            !init &&
            <Button onClick={onLoginClick} variant="contained" color={"secondary"} disabled={loading}>
                <Avatar src="plex-icon.png" alt="Image Alt Text" sx={{marginRight: 1}}/>
                Se connecter avec plex
            </Button>
        }

    </Box>
}