import {Navigate, Route, Routes} from "react-router-dom";
import MediaDiscoverPage from "./application/pages/media/discover/MediaDiscoverPage";
import * as React from "react";
import MediaMovieDetailPage from "./application/pages/media/detail/movie/MediaMovieDetailPage";
import MediaPersonDetailPage from "./application/pages/media/detail/person/MediaPersonDetailPage";
import MediaCollectionDetailPage from "./application/pages/media/detail/collection/MediaCollectionDetailPage";
import MediaTvDetailPage from "./application/pages/media/detail/tv/MediaTvDetailPage";
import MediaTvSeasonDetailPage from "./application/pages/media/detail/tv/MediaTvSeasonPage";
import MediaSearchPage from "./application/pages/media/search/MediaSearchPage";
import MediaDiscoverTrendingAllByDayPage from "./application/pages/media/discover/MediaDiscoverTrendingAllByDayPage";
import MediaDiscoverPopularMoviesPage from "./application/pages/media/discover/MediaDiscoverPopularMoviesPage";
import MediaDiscoverPopularTvsPage from "./application/pages/media/discover/MediaDiscoverPopularTvsPage";
import MediaDiscoverUpcomingMoviesPage from "./application/pages/media/discover/MediaDiscoverUpcomingMoviesPage";
import MediaDiscoverUpcomingTvsPage from "./application/pages/media/discover/MediaDiscoverUpcomingTvsPage";
import SettingPage from "./application/pages/setting/SettingPage";
import MediaDiscoverRequestedMoviesPage from "./application/pages/media/discover/MediaDiscoverRequestedMoviesPage";
import MediaDiscoverLastUpdatedTvsPage from "./application/pages/media/discover/MediaDiscoverLastUpdatedTvsPage";
import MediaDiscoverProviderMoviesPage
    from "./application/pages/media/discover/providers/MediaDiscoverProviderMoviesPage";
import MediaDiscoverProviderTvsPage from "./application/pages/media/discover/providers/MediaDiscoverProviderTvsPage";
import YoutubeDiscoverPage from "./application/pages/youtube/discover/YoutubeDiscoverPage";
import YoutubeChannelDetailPage from "./application/pages/youtube/detail/channel/YoutubeChannelDetailPage";

export default function AppRoutes() {

    return <Routes>
        <Route path="/settings" element={<SettingPage/>}/>
        <Route path="/youtube/discover" element={<YoutubeDiscoverPage/>}/>
        <Route path="/youtube/channels/:channelId" element={<YoutubeChannelDetailPage/>}/>
        <Route path="/medias/discover" element={<MediaDiscoverPage/>}/>
        <Route path="/medias/discover/trending" element={<MediaDiscoverTrendingAllByDayPage/>}/>
        <Route path="/medias/discover/movies/popular" element={<MediaDiscoverPopularMoviesPage/>}/>
        <Route path="/medias/discover/movies/upcoming" element={<MediaDiscoverUpcomingMoviesPage/>}/>
        <Route path="/medias/discover/movies/requested" element={<MediaDiscoverRequestedMoviesPage/>}/>
        <Route path="/medias/discover/movies/netflix"
               element={<MediaDiscoverProviderMoviesPage provider={"NETFLIX"}/>}/>
        <Route path="/medias/discover/movies/amazon"
               element={<MediaDiscoverProviderMoviesPage provider={"AMAZON_PRIME_VIDEO"}/>}/>
        <Route path="/medias/discover/movies/disney"
               element={<MediaDiscoverProviderMoviesPage provider={"DISNEY_PLUS"}/>}/>
        <Route path="/medias/discover/movies/apple"
               element={<MediaDiscoverProviderMoviesPage provider={"APPLE_TV_PLUS"}/>}/>
        <Route path="/medias/discover/tvs/netflix"
               element={<MediaDiscoverProviderTvsPage provider={"NETFLIX"}/>}/>
        <Route path="/medias/discover/tvs/amazon"
               element={<MediaDiscoverProviderTvsPage provider={"AMAZON_PRIME_VIDEO"}/>}/>
        <Route path="/medias/discover/tvs/disney"
               element={<MediaDiscoverProviderTvsPage provider={"DISNEY_PLUS"}/>}/>
        <Route path="/medias/discover/tvs/apple"
               element={<MediaDiscoverProviderTvsPage provider={"APPLE_TV_PLUS"}/>}/>
        <Route path="/medias/discover/tvs/popular" element={<MediaDiscoverPopularTvsPage/>}/>
        <Route path="/medias/discover/tvs/upcoming" element={<MediaDiscoverUpcomingTvsPage/>}/>
        <Route path="/medias/discover/tvs/last-updated" element={<MediaDiscoverLastUpdatedTvsPage/>}/>
        <Route path="/medias/search" element={<MediaSearchPage/>}/>
        <Route path="/medias/detail/movies/:movieId" element={<MediaMovieDetailPage/>}/>
        <Route path="/medias/detail/tvs/:tvId" element={<MediaTvDetailPage/>}/>
        <Route path="/medias/detail/tvs/:tvId/seasons/:seasonNumber" element={<MediaTvSeasonDetailPage/>}/>
        <Route path="/medias/detail/persons/:personId" element={<MediaPersonDetailPage/>}/>
        <Route path="/medias/detail/collections/:collectionId" element={<MediaCollectionDetailPage/>}/>
        <Route path="*" element={<Navigate to="/medias/discover"/>}/>
    </Routes>
}