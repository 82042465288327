import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom";
import {Box} from "@mui/material";

interface TitleProps {
    label: string;
    link?: string;
}

export default function Title({label, link}: TitleProps) {

    const labelPart = <Typography variant="h5" style={{fontWeight: 'bold'}}>
        {label}
    </Typography>;

    if (link) {

        return <Box component={Link} to={link} sx={{color: 'white', textDecoration: 'none'}}>{labelPart}</Box>;
    } else {

        return labelPart;
    }
}