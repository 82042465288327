import {Box} from "@mui/material";
import {TvDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import TvHeader from "./TvHeader";
import MediaSearchResultCardSlider from "../../../core/media/slider/MediaSearchResultCardSlider";
import React from "react";
import MediaTvSeasonCardContainer from "../../../core/media/container/MediaTvSeasonCardContainer";
import TvAvailabilityDetail from "./TvAvailabilityDetail";
import AlternateTitleInput from "../../../download/alternate_title/AlternateTitleInput";

export interface TvDetailContainerProps {
    tv: TvDetail
}

export default function TvDetailContainer({tv}: TvDetailContainerProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <TvHeader tv={tv}/>
        <TvAvailabilityDetail tv={tv}/>
        <Box margin={2}>
            <AlternateTitleInput media={tv}/>
            <Box margin={2}/>
            <MediaSearchResultCardSlider title={'Casting'} data={tv.casts.cast}/>
            <MediaTvSeasonCardContainer title={'Saisons'} idTv={tv.id} data={tv.seasons}/>
        </Box>
    </Box>
}