import {Chip} from "@mui/material";
import {MediaType} from "../../../../../domain/commons/model/media/MediaSearchModels";

export interface MediaTypeChipProps {
    type: MediaType
}

export default function MediaTypeChip({type}: MediaTypeChipProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    let color;
    let label;
    switch (type) {
        case 'MOVIE':
            label = 'FILM';
            color = '#0b62cdd4';
            break;
        case 'TV':
            label = 'SERIE';
            color = '#8b0bcdd4';
            break;
        default:
            label = 'UNKNOWN';
    }

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Chip label={label} size="small" sx={{
        position: 'absolute',
        top: 5,
        left: 5,
        backgroundColor: color,
        border: '1px solid #ffffff2e',
        zIndex: 1
    }}/>
}