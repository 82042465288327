import {Box} from "@mui/material";
import React from "react";
import MediaSkeletonCard from "../../card/skeleton/MediaSkeletonCard";
import Title from "../../../title/Title";
import {uuidv4} from "../../../../../../domain/commons/utils/UUIDProvider";

export interface MediaSkeletonCardSliderProps {
    title: string
}

export default function MediaSkeletonCardSlider({title}: MediaSkeletonCardSliderProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <Title label={title}/>
        <Box sx={{display: 'flex', flexWrap: "nowrap", overflowX: "auto"}} className={"mediaCardSlider"}>
            {Array.from({length: 10}).map(() => (
                <MediaSkeletonCard key={uuidv4()}/>
            ))}
        </Box>
    </Box>
}