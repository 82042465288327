import {Box, Grid, Skeleton} from "@mui/material";

export default function MediaHeaderSkeleton() {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <Box margin={2}>
            <Grid container spacing={3}>
                {/* Poster */}
                <Grid item width={250}>
                    <Skeleton variant="rectangular" width={224} height={338}/>
                </Grid>
                {/* Header */}
                <Grid item xs={12} md={8} lg={6}>
                    <Box margin={2}>
                        <Box display={"flex"} gap={2}>
                            <Skeleton height={60} width='100%'/>
                        </Box>
                        <Box display={"flex"} gap={2}>
                            <Skeleton height={40} width='50%'/>
                        </Box>
                        <Box display="flex"><Skeleton height={40} width='50%'/></Box>
                        <Box display="flex"><Skeleton height={150} width='100%'/></Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Box>
}