import {Box, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useMQ} from "../../../../theme";
import {Link, useLocation} from "react-router-dom";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';

export interface AppMenuProps {
    open: boolean,
    setOpen: (value: boolean) => void
}

export default function SidebarMenu({open, setOpen}: AppMenuProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState("");

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const menuWidth = 240;

    // Menu content
    const menuItems = [
        getItem("Découvrir", "/medias/discover", <AutoAwesomeOutlinedIcon/>),
        getItem("Youtube", "/youtube/discover", <YouTubeIcon/>),
        getItem("Paramètres", "/settings", <SettingsOutlinedIcon/>)
    ]

    const isPermanentAppMenu = useMQ().isPermanentAppMenu;

    const permanentDrawer = <Drawer variant="permanent" sx={{flexShrink: 0}} classes={{paper: 'position-relative'}}>
        <Box minWidth={menuWidth}>
            <List component="nav">
                {menuItems}
            </List>
        </Box>
    </Drawer>;

    const temporaryDrawer = <Drawer open={open} onClose={() => setOpen(false)}>
        <Box minWidth={menuWidth}>
            <List component="nav">
                {menuItems}
            </List>
        </Box>
    </Drawer>;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    function getItem(title: string, link: string, icon: React.ReactNode): React.ReactNode {

        return <ListItemButton component={Link} to={link} key={link} selected={selectedKey === link}>
            <ListItemIcon>{icon}</ListItemIcon><ListItemText
            primary={<Typography variant="body1" style={{fontWeight: 'bold'}}>
                {title}
            </Typography>}/>
        </ListItemButton>
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        const pathname = location.pathname;
        setSelectedKey(pathname);
        setOpen(false);
    }, [location, setOpen]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return isPermanentAppMenu ? permanentDrawer : temporaryDrawer;
}