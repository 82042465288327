import {Box} from "@mui/material";
import {MediaRelease} from "../../../../../../domain/commons/model/media/MediaReleaseModels";
import MediaReleaseCard from "../../card/release/MediaReleaseCard";
import Title from "../../../title/Title";
import {uuidv4} from "../../../../../../domain/commons/utils/UUIDProvider";

export interface MediaReleaseCardContainerProps {
    title: string,
    data?: MediaRelease[]
    onReleaseDownloadClick?: (release: MediaRelease) => void
}

export default function MediaReleaseCardContainer({title, data, onReleaseDownloadClick}: MediaReleaseCardContainerProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasData = data !== undefined && data.length > 0;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (hasData) {

        return <Box>
            <Title label={title}/>
            <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
                {data.map((item) => (
                    <MediaReleaseCard onDownloadClick={onReleaseDownloadClick} data={item} key={uuidv4()}/>
                ))}
            </Box>
        </Box>
    } else {

        return <Box/>
    }
}