import {Box, Typography} from "@mui/material";
import MediaBasicCard from "./MediaBasicCard";
import {TvSeason} from "../../../../../domain/commons/model/media/MediaCoreModels";

export interface MediaTvSeasonCardProps {
    season: TvSeason,
    idTv: number
}

export default function MediaTvSeasonCard({season, idTv}: MediaTvSeasonCardProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const posterImage = season.imagePath;
    const link = '/medias/detail/tvs/' + idTv + '/seasons/' + season.seasonNumber;
    const numberOfEpisodes = season.episodeCount + (season.episodeCount > 1 ? ' Episodes' : ' Episode');

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const hoverContent = <Box sx={{marginTop: 10}}>
        <Typography className={"mediaCardTitle"} fontSize={20} fontWeight={900}
                    lineHeight={1}>{season.title}</Typography>
        <Typography fontSize={14}>{numberOfEpisodes}</Typography>
    </Box>

    return <MediaBasicCard hoverContent={hoverContent} posterImage={posterImage} link={link} availability={season.availability}/>
}