import User from "../../../../domain/commons/model/user/User";
import {Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import {Logout} from "@mui/icons-material";
import UserService from "../../../../domain/service/user/UserService";

interface UserAvatarProps {
    user?: User;
}

export default function UserAvatar({user}: UserAvatarProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const userService = UserService;
    const open = Boolean(anchorEl);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = async () => {
        await userService.logout();
    };

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <IconButton onClick={handleClick} size="small" sx={{ml: 2}}>
            <Avatar src={user?.avatar}/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            id="user-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <Logout fontSize="small"/>
                </ListItemIcon>
                Se déconnecter
            </MenuItem>
        </Menu>
    </Box>
}