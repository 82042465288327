import * as React from "react";
import {useState} from "react";
import {Box, Button} from "@mui/material";
import {MediaDetail} from "../../../../domain/commons/model/media/MediaDetailModels";
import AlternateTitleDrawer from "./AlternateTitleDrawer";

export interface AlternateTitleInputProps {
    media: MediaDetail
}

export default function AlternateTitleInput({media}: AlternateTitleInputProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [showDrawer, setShowDrawer] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleShow = () => {

        setShowDrawer(true);
    };

    const onDrawerClose = () => {
        setShowDrawer(false);
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <Button variant={"outlined"} onClick={handleShow}>Titres alternatifs</Button>
        <AlternateTitleDrawer open={showDrawer} media={media} onClose={onDrawerClose}/>
    </Box>;
}