import {Box} from "@mui/material";
import YoutubeChannelHeader from "./YoutubeChannelHeader";
import {YoutubeChannelDetail} from "../../../../../domain/commons/model/youtube/YoutubeModels";
import YoutubeVideoCardContainer from "../../../core/youtube/container/YoutubeVideoCardContainer";

export interface YoutubeChannelDetailContainerProps {
    channel: YoutubeChannelDetail
}

export default function YoutubeChannelDetailContainer({channel}: YoutubeChannelDetailContainerProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <YoutubeChannelHeader channel={channel}/>
        <Box margin={2}>
            <YoutubeVideoCardContainer title={"Vidéos"} data={channel.videos} />
        </Box>
    </Box>
}