import {Box} from "@mui/material";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import {SearchResult} from "../../../../../domain/commons/model/media/MediaSearchModels";
import React from "react";
import Title from "../../title/Title";
import MediaSearchResultCard from "../card/MediaSearchResultCard";

export interface MediaSearchResultCardSliderProps {
    title: string,
    link?: string,
    data: SearchResult[]
}

export default function MediaSearchResultCardSlider({title, link, data}: MediaSearchResultCardSliderProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasData = data !== undefined && data.length > 0;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (hasData) {
        return <Box>
            <Title label={title} link={link}/>
            <Box sx={{display: 'flex', flexWrap: "nowrap", overflowX: "auto"}} className={"mediaCardSlider"}>
                {data.map((item) => (
                    <MediaSearchResultCard data={item} key={uuidv4()}/>
                ))}
            </Box>
        </Box>
    } else {
        return <Box/>
    }
}