import axios from "axios";
import {TvDetail, TvSeasonDetail} from "../../../../commons/model/media/MediaDetailModels";

const fetchOne = (id: number) => axios.get<TvDetail>(`/api/medias/tvs/${id}`);
const fetchSeason = (idTv: number, seasonNumber: number) => axios.get<TvSeasonDetail>(`/api/medias/tvs/${idTv}/seasons/${seasonNumber}`);

const MediaTvService = {
    fetchOne,
    fetchSeason
}

export default MediaTvService;