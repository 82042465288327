import {Typography} from "@mui/material";

export interface MediaDurationProps {
    runtime: number
}

export default function MediaDuration({runtime}: MediaDurationProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hours = Math.floor(runtime / 60);
    const minutes = Math.floor(runtime % 60);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Typography>{hours && hours > 0 ? hours + 'h' : undefined}{minutes && minutes > 0 ? minutes + 'm' : undefined}</Typography>
}