import {Box} from "@mui/material";
import {TvEpisode} from "../../../../../domain/commons/model/media/MediaCoreModels";
import Title from "../../title/Title";
import MediaTvEpisodeCard from "../card/MediaTvEpisodeCard";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";

export interface MediaTvEpisodeCardContainerProps {
    title: string
    data: TvEpisode[]
    onReleaseClick: (episode: TvEpisode) => void
}

export default function MediaTvEpisodeCardContainer({title, data, onReleaseClick}: MediaTvEpisodeCardContainerProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasData = data !== undefined && data.length > 0;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (hasData) {

        return <Box>
            <Title label={title}/>
            <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
                {data.map((item) => (
                    <MediaTvEpisodeCard episode={item} onReleaseClick={onReleaseClick} key={uuidv4()}/>
                ))}
            </Box>
        </Box>
    } else {

        return <Box/>
    }
}