import axios from "axios";
import {PlexLibrary, PlexSetting} from "../../commons/model/plex/PlexModels";

const fetchSettings = () => axios.get<PlexSetting>("/api/plex/settings");
const fetchLibraries = () => axios.get<PlexLibrary[]>("/api/plex/settings/libraries");
const selectPlexServer = (serverUrl: string) => axios.post(`/api/plex/settings/select-server`, null, {
    params: {
        "serverUrl": serverUrl
    }
});

const PlexService = {
    fetchSettings,
    fetchLibraries,
    updatePlexServerUrl: selectPlexServer
}

export default PlexService;