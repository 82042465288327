import {Box} from "@mui/material";
import {TvSeason} from "../../../../../domain/commons/model/media/MediaCoreModels";
import Title from "../../title/Title";
import MediaTvSeasonCard from "../card/MediaTvSeasonCard";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";

export interface MediaTvSeasonCardContainerProps {
    title: string,
    data: TvSeason[]
    idTv: number
}

export default function MediaTvSeasonCardContainer({title, data, idTv}: MediaTvSeasonCardContainerProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasData = data !== undefined && data.length > 0;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (hasData) {

        return <Box>
            <Title label={title}/>
            <Box sx={{display: 'flex', flexWrap: "wrap"}}>
                {data.map((item) => (
                    <MediaTvSeasonCard season={item} idTv={idTv} key={uuidv4()}/>
                ))}
            </Box>
        </Box>
    } else {

        return <Box/>
    }
}