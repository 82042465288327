import * as React from "react";
import {useCallback, useState} from "react";
import {PlexLibrary} from "../../../../domain/commons/model/plex/PlexModels";
import {Box, Card, IconButton, Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import PlexLibrarySettingDrawer from "./PlexLibrarySettingDrawer";
import DownloadProfileService from "../../../../domain/service/download/profile/DownloadProfileService";

export interface PlexLibrarySettingInputProps {
    library: PlexLibrary,
    width?: number,
    onUpdate: () => void,
    disabled?: boolean
}

export default function PlexLibrarySettingInput({library, width, onUpdate, disabled}: PlexLibrarySettingInputProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [showDrawer, setShowDrawer] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const synced = library.downloadProfile !== undefined;
    const cardWidth = width ? width : 350;
    const downloadProfileService = DownloadProfileService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const deleteProfile = useCallback(async () => {
        const result = await downloadProfileService.deleteDownloadProfile(library.idPlexLibrary);
        if (result.status === 200) {
            onUpdate();
        }
    }, [downloadProfileService, library.idPlexLibrary, onUpdate]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleChange = (synced: boolean) => {

        if (synced) {
            setShowDrawer(true);
        } else {
            deleteProfile();
        }
    };

    const onDrawerClose = () => {
        setShowDrawer(false);
        onUpdate();
    }

    const onEdit = () => {
        setShowDrawer(true);
    }
    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Card sx={{
        height: 50,
        width: cardWidth,
        margin: 1,
        display: 'flex',
        alignItems: 'center',
        padding: 1,
        justifyContent: 'space-between'
    }}>
        <PlexLibrarySettingDrawer open={showDrawer} library={library} onClose={onDrawerClose}/>
        <Typography>{library.title}</Typography>
        <Box>
            {synced && <IconButton onClick={onEdit} disabled={disabled}><EditIcon/></IconButton>}
            <Switch defaultChecked={synced} disabled={disabled}
                    onChange={(event) => handleChange(event.target.checked)}/>
        </Box>
    </Card>
}