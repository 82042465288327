import {Chip} from "@mui/material";
import {format} from "date-fns";
import {fr} from "date-fns/locale";

export interface MediaReleaseDateChipProps {
    releaseDate: string
}

export default function MediaReleaseDateChip({releaseDate}: MediaReleaseDateChipProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const formattedAirDate = format(new Date(releaseDate), 'dd MMMM yyyy', {locale: fr})

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Chip label={formattedAirDate} size="small" sx={{
        position: 'absolute',
        bottom: 10,
        right: 10,
        border: '1px solid #ffffff2e',
        zIndex: 1
    }}/>
}