import {Box, IconButton} from "@mui/material";
import {formatLinks} from "../../../../domain/commons/utils/FormatUtils";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useState} from "react";

export interface FormattedTextBoxProps {
    text: string,
    maxHeaderLines?: number
}

export default function FormattedTextBox({text, maxHeaderLines}: FormattedTextBoxProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [expended, setExpended] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const textArray = text.split('\n');
    const maxSize = maxHeaderLines ? maxHeaderLines : textArray.length;
    const firstPart = textArray.slice(0, maxSize);
    const secondPart = textArray.slice(maxSize);

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function handleExpend() {
        setExpended(previous => !previous);
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const expandButton = <IconButton onClick={handleExpend}>
        {expended ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton> ;

    return <Box className={"whiteLinks"}>
        {firstPart && firstPart.map((line, index) => (
            <Box key={index}>
                <span dangerouslySetInnerHTML={{__html: formatLinks(line)}}/>
                <br/>
            </Box>
        ))}
        {(secondPart && expended) && secondPart.map((line, index) => (
            <Box key={index}>
                <span dangerouslySetInnerHTML={{__html: formatLinks(line)}}/>
                <br/>
            </Box>
        ))}
        {expandButton}
    </Box>
}