import * as React from "react";
import {useCallback, useState} from "react";
import Box from '@mui/material/Box';
import PlexSettingLibrarySelector from "../plex/setting/PlexSettingLibrarySelector";
import MediaSynchronizationService from "../../../domain/service/media/synchronization/MediaSynchronizationService";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function SettingLibrary() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [syncLoading, setSyncLoading] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    let mediaSynchronizationService = MediaSynchronizationService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const synchronize = useCallback(async () => {
        setSyncLoading(true);
        mediaSynchronizationService.synchronizes()
            .finally(() => {
                setSyncLoading(false);
            })
    }, [mediaSynchronizationService]);


    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleOnLibrariesSelected = () => {
        synchronize();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/


    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box marginTop={2}>
        <PlexSettingLibrarySelector onLibrariesSelected={handleOnLibrariesSelected} disabled={syncLoading}/>
        {syncLoading && <Box marginTop={1} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress/> <Typography> Synchronisation en cours</Typography>
        </Box>}
    </Box>
}