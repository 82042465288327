import {Box, Button} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {TvDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import DownloadRequestService from "../../../../../domain/service/download/request/DownloadRequestService";
import MediaDownloadStatusChip from "../../../core/media/chip/MediaDownloadStatusChip";
import DeleteIcon from '@mui/icons-material/Delete';
import MediaTvService from "../../../../../domain/service/media/detail/tv/MediaTvService";
import MediaTvRequestButton from "../../request/MediaTvRequestButton";

export interface TvAvailabilityDetailProps {
    tv: TvDetail
}

export default function TvAvailabilityDetail({tv}: TvAvailabilityDetailProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [statusTv, setStatusTv] = useState<TvDetail>();
    const refreshIntervalRef = useRef<number | null>(null);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const mediaTvService = MediaTvService;
    const downloadRequestService = DownloadRequestService;
    const existsRequest = statusTv?.requestStatus !== undefined;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const refreshTv = useCallback(async () => {

        mediaTvService.fetchOne(tv.id)
            .then((response => {
                setStatusTv(response.data);
            }));
    }, [tv, mediaTvService]);

    const deleteRequest = useCallback(async () => {

        downloadRequestService.deleteTvRequest(tv.id)
            .then((response => {
                refreshTv();
            }));
    }, [tv, downloadRequestService, refreshTv]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const onRequestCreated = () => {
        refreshTv();
    }

    const onDeleteRequestClick = () => {
        deleteRequest();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        setStatusTv(tv);
        refreshIntervalRef.current = window.setInterval(refreshTv, 1000);
        return () => {
            if (refreshIntervalRef.current !== null) {
                clearInterval(refreshIntervalRef.current);
            }
        };
    }, [tv, refreshTv]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box margin={2} display={"flex"} gap={1}>
        {statusTv && <MediaDownloadStatusChip chipType={"chip"} mediaType={"TV"} availability={statusTv.availability}
                                              requestStatus={statusTv.requestStatus}/>}
        {(!existsRequest) && <MediaTvRequestButton idTv={tv.id} onRequestCreated={onRequestCreated}/>}
        {existsRequest &&
            <Button onClick={onDeleteRequestClick} variant={"outlined"} color={"error"} startIcon={<DeleteIcon/>}>
                Supprimer la requête
            </Button>
        }
    </Box>
}