import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {CollectionDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaCollectionService from "../../../../../domain/service/media/detail/collection/MediaCollectionService";
import MediaHeaderSkeleton from "../../../../component/media/detail/core/header/skeleton/MediaHeaderSkeleton";
import CollectionDetailContainer from "../../../../component/media/detail/collection/CollectionDetailContainer";
import LoadingAlert from "../../../../component/core/alert/LoadingAlert";

export default function MediaCollectionDetailPage() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [collection, setCollection] = useState<CollectionDetail>();

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const mediaCollectionService = MediaCollectionService;
    const {collectionId} = useParams();

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchCollection = useCallback(async () => {

        const id = collectionId ? +collectionId : 0;
        setLoading(true);
        mediaCollectionService.fetchOne(id)
            .then((response => {
                setShowServerError(false);
                setCollection(response.data);
            }))
            .catch(onServerError)
            .finally(() => setLoading(false));

    }, [collectionId, mediaCollectionService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchCollection();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchCollection();
    }, [fetchCollection]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (loading) {
        return <MediaHeaderSkeleton/>
    } else if (showServerError || collection === undefined) {
        return <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        return <CollectionDetailContainer collection={collection}/>
    }
}