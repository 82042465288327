import React, {useCallback, useEffect, useState} from "react";
import {SearchResult} from "../../../../domain/commons/model/media/MediaSearchModels";
import MediaDiscoverService from "../../../../domain/service/media/discover/MediaDiscoverService";
import MediaSkeletonCardSlider from "../../core/media/slider/skeleton/MediaSkeletonCardSlider";
import LoadingAlert from "../../core/alert/LoadingAlert";
import MediaSearchResultCardSlider from "../../core/media/slider/MediaSearchResultCardSlider";

export default function MediaDiscoverUpcomingMovies() {
    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [data, setData] = useState<SearchResult[]>([]);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const discoverService = MediaDiscoverService;
    const sliderTitle = "Films à venir";
    const sliderLink = "/medias/discover/movies/upcoming";

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchData = useCallback(async () => {

        setLoading(true);
        discoverService.upcomingMovies("BASE", 1)
            .then((response => {
                setShowServerError(false);
                setData(response.data.results);
            })).catch(onServerError)
            .finally(() => setLoading(false));

    }, [discoverService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {

        fetchData();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchData();
    }, [fetchData]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (loading) {
        return <MediaSkeletonCardSlider title={sliderTitle}/>
    } else if (showServerError) {
        return <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        return <MediaSearchResultCardSlider title={sliderTitle} data={data} link={sliderLink}/>
    }
}