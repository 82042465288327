import React, {useCallback, useEffect, useState} from "react";
import {YoutubeDiscoverChannel} from "../../../../domain/commons/model/youtube/YoutubeModels";
import YoutubeDiscoverService from "../../../../domain/service/youtube/YoutubeDiscoverService";
import LoadingAlert from "../../core/alert/LoadingAlert";
import YoutubeChannelCardSlider from "../../core/youtube/slider/YoutubeChannelCardSlider";
import YoutubeChannelSkeletonCardSlider from "../../core/youtube/skeleton/YoutubeChannelSkeletonCardSlider";


export default function YoutubeDiscoverSyncedChannels() {
    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [data, setData] = useState<YoutubeDiscoverChannel[]>([]);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const youtubeDiscoverService = YoutubeDiscoverService;
    const sliderTitle = "Chaînes Synchronisées";
    const sliderLink = "/youtube/discover/channels";

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchData = useCallback(async () => {

        setLoading(true);
        youtubeDiscoverService.discoverSyncedChannels()
            .then((response => {
                setShowServerError(false);
                setData(response.data);
            })).catch(onServerError)
            .finally(() => setLoading(false));

    }, [youtubeDiscoverService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {

        fetchData();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchData();
    }, [fetchData]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (loading) {
        return <YoutubeChannelSkeletonCardSlider title={sliderTitle}/>
    } else if (showServerError) {
        return <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        return <YoutubeChannelCardSlider title={sliderTitle} data={data} link={sliderLink}/>
    }
}