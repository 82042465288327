import {Box, Typography} from "@mui/material";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import {TvDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaVote from "../core/MediaVote";
import MediaGenres from "../core/MediaGenres";
import Title from "../../../core/title/Title";
import BaseHeader from "../core/header/BaseHeader";
import {translateTvStatus} from "../../../../../domain/commons/constants/Constants";

export interface TvHeaderProps {
    tv: TvDetail
}

export default function TvHeader({tv}: TvHeaderProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const status = translateTvStatus(tv.status);
    const hasVote = tv?.voteAverage !== undefined && tv?.voteCount !== undefined && tv.voteCount > 0;
    const hasGenres = tv?.genres !== undefined && tv.genres.length > 0;
    const numberOfSeasons = tv.numberOfSeasons + (tv.numberOfSeasons > 1 ? ' Saisons' : ' Saison');
    const subDetailArray = [];
    if (hasVote) subDetailArray.push(<MediaVote key={uuidv4()} voteAverage={tv.voteAverage} voteCount={tv.voteCount}/>);
    if (hasGenres) subDetailArray.push(<MediaGenres key={uuidv4()} genres={tv.genres}/>);
    subDetailArray.push(<Typography key={uuidv4()}>{numberOfSeasons}</Typography>);
    subDetailArray.push(<Typography key={uuidv4()}>{status}</Typography>);
    const separatedSubDetailArray = subDetailArray.flatMap((child, index) => {
        if (index === subDetailArray.length - 1) {
            return child;
        } else {
            return [child, <span key={uuidv4()}>|</span>];
        }
    });

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const headerContent = <Box margin={2}>
        <Box display={"flex"} gap={2}>
            <Typography variant="h4" style={{fontWeight: 'bold'}}>{tv.title}</Typography>
            {tv?.year && <Typography variant="h4">({tv.year})</Typography>}
        </Box>
        <Box display={"flex"} gap={2}>
            {separatedSubDetailArray}
        </Box>
        {tv.overview && <Box>
            <Box marginTop={2}/>
            <Title label={'Résumé'}/>
            <Typography style={{marginTop: 5}}>{tv.overview}</Typography>
        </Box>}
    </Box>

    return <BaseHeader headerContent={headerContent} posterImage={tv.imagePath}
                       backdropImage={tv.backdropPath}/>
}