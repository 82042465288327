import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {TvSeasonDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaTvService from "../../../../../domain/service/media/detail/tv/MediaTvService";
import MediaHeaderSkeleton from "../../../../component/media/detail/core/header/skeleton/MediaHeaderSkeleton";
import LoadingAlert from "../../../../component/core/alert/LoadingAlert";
import TvSeasonDetailContainer from "../../../../component/media/detail/tv/TvSeasonDetailContainer";


export default function MediaTvSeasonDetailPage() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [data, setData] = useState<TvSeasonDetail>();

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const mediaTvService = MediaTvService;
    const {tvId, seasonNumber} = useParams();

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchCollection = useCallback(async () => {

        if (tvId && seasonNumber) {
            setLoading(true);
            mediaTvService.fetchSeason(+tvId, +seasonNumber)
                .then((response => {
                    setShowServerError(false);
                    setData(response.data);
                }))
                .catch(onServerError)
                .finally(() => setLoading(false));
        }

    }, [tvId, seasonNumber, mediaTvService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchCollection();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchCollection();
    }, [fetchCollection]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (loading) {
        return <MediaHeaderSkeleton/>
    } else if (showServerError || data === undefined) {
        return <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        return <TvSeasonDetailContainer season={data}/>
    }
}