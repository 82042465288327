import {Box} from "@mui/material";
import * as React from "react";
import {CollectionDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import SortedMediaResultCardContainer from "../../../core/media/container/SortedMediaResultCardContainer";
import CollectionHeader from "./CollectionHeader";

export interface CollectionDetailContainerProps {
    collection: CollectionDetail
}

export default function CollectionDetailContainer({collection}: CollectionDetailContainerProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <CollectionHeader collection={collection}/>
        <Box margin={2}>
            <SortedMediaResultCardContainer data={collection?.collection} title={'Collection'}/>
        </Box>
    </Box>
}