import {Chip} from "@mui/material";
import {format, isBefore, isSameDay, parseISO} from "date-fns";
import {fr} from "date-fns/locale";

export interface MediaTvEpisodeAirdateChipProps {
    airDate: string
}

export default function MediaTvEpisodeAirDateChip({airDate}: MediaTvEpisodeAirdateChipProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const formattedAirDate = format(new Date(airDate), 'dd MMMM yyyy', {locale: fr})
    const chipColor = isDateOlderOrEqualToday(airDate) ? 'primary' : 'default';

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    function isDateOlderOrEqualToday(dateString: string): boolean {
        const parsedDate = parseISO(dateString); // Parse the date string into a Date object
        const today = new Date(); // Get the current date and time

        // Check if the date is equal to or before today
        return isSameDay(parsedDate, today) || isBefore(parsedDate, today);
    }

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Chip label={formattedAirDate} size="small" color={chipColor} sx={{
        position: 'absolute',
        bottom: 10,
        right: 60,
        border: '1px solid #ffffff2e',
        zIndex: 1
    }}/>
}