import {useEffect, useRef, useState} from "react";
import {Box, Card, CardActionArea, CardContent, CardMedia, Chip, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {YoutubeDiscoverVideo} from "../../../../../domain/commons/model/youtube/YoutubeModels";

export interface YoutubeVideoCardProps {
    video: YoutubeDiscoverVideo
    channelTitle?: string
}

export default function YoutubeVideoCard({video,channelTitle}: YoutubeVideoCardProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const componentRef = useRef<HTMLDivElement | null>(null);
    const [showDetail, setShowDetail] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const link = "/youtube/videos/" + video.id;

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onMouseEnter() {
        setShowDetail(true);
    }

    function onMouseLeave() {
        setShowDetail(false);
    }

    function onClickOutside(event: MouseEvent) {
        if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
            setShowDetail(false);
        }
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);

        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, []);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

        // prepare content
    const hoverContent = <Box sx={{marginTop: 5}}>
            <Typography className={"mediaCardTitle"} fontSize={20} fontWeight={900}
                        lineHeight={1}>{video.title}</Typography>
        </Box>

    let cardContent;
    if (showDetail) {
        cardContent = <Link to={link}>
            <CardContent
                sx={{
                    position: 'absolute', bottom: 0, left: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', color: '#fff', width: '100%', height: '100%'
                }}
            >
                {hoverContent}
            </CardContent>
        </Link>
    }

    // render

    return <Box>
        <Card ref={componentRef}
              sx={{
                  borderRadius: '12px',
                  width: 320,
                  height: 180,
                  margin: 1,
                  flexShrink: 0,
                  border: '1px solid #313A47'
              }}
              className={"mediaCard"}
              onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <CardActionArea>
                {channelTitle && <Chip label={channelTitle} size="small" sx={{
                    position: 'absolute', bottom: 5, right: 5,
                    fontWeight: 900,
                    backgroundColor: '#1d2635', border: '1px solid #ffffff2e',
                    zIndex: 1
                }}/>}
                <CardMedia component="img" image={video.imagePath} referrerPolicy={"no-referrer"}/>
                {cardContent}
            </CardActionArea>
        </Card>
        <Typography width={320} fontSize={14} fontWeight={900} padding={1}
                    maxHeight={37}
                    className={"youtubeCardTitle"} lineHeight={1}>{video.title}</Typography>
    </Box>
}