export const Constants = {
    localStorage: {
        plexToken: "plex-token"
    },
    assets: {
        collectionBaseUrl: 'https://image.tmdb.org/t/p/w1440_and_h320_multi_faces/',
        backdropBaseUrl: 'https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces',
        posterBaseUrl: 'https://www.themoviedb.org/t/p/w220_and_h330_face',
        episodeBaseUrl: 'https://www.themoviedb.org/t/p/w227_and_h127_bestv2',
        noPosterUrl: '/assets/no_image.png',
        tmdbLogoUrl: '/assets/tmdb_logo.svg',
    }
}

export function translateTvStatus(status: string): string {

    let translatedStatus: string;
    switch (status) {
        case 'Returning Series':
            translatedStatus = 'Émission renouvelée';
            break;
        case 'Planned':
            translatedStatus = 'Émission annoncée';
            break;
        case 'Pilot':
            translatedStatus = 'Émission en phase pilote';
            break;
        case 'In Production':
            translatedStatus = 'Émission en production';
            break;
        case 'Ended':
            translatedStatus = 'Émission terminée';
            break;
        case 'Cancelled':
            translatedStatus = 'Émission annulée';
            break;
        default:
            translatedStatus = status;
    }

    return translatedStatus;
}