import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {YoutubeChannelDetail} from "../../../../../domain/commons/model/youtube/YoutubeModels";
import YoutubeDetailService from "../../../../../domain/service/youtube/YoutubeDetailService";
import LoadingAlert from "../../../../component/core/alert/LoadingAlert";
import YoutubeChannelDetailContainer from "../../../../component/youtube/detail/channel/YoutubeChannelDetailContainer";
import MediaHeaderSkeleton from "../../../../component/media/detail/core/header/skeleton/MediaHeaderSkeleton";


export default function YoutubeChannelDetailPage() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [channel, setChannel] = useState<YoutubeChannelDetail>();

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const youtubeDetailService = YoutubeDetailService;
    const {channelId} = useParams();

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchChannel = useCallback(async () => {

        if (channelId !== undefined) {
            setLoading(true);
            youtubeDetailService.findChannelById(channelId)
                .then((response => {
                    setShowServerError(false);
                    setChannel(response.data);
                }))
                .catch(onServerError)
                .finally(() => setLoading(false));
        } else {
            setShowServerError(true);
        }

    }, [channelId, youtubeDetailService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchChannel();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchChannel();
    }, [fetchChannel]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (loading) {
        return <MediaHeaderSkeleton/>
    } else if (showServerError || channel === undefined) {
        return <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        return <YoutubeChannelDetailContainer channel={channel}/>
    }
}