import {Box, Typography} from "@mui/material";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import {MovieDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaVote from "../core/MediaVote";
import React from "react";
import MediaDuration from "../core/MediaDuration";
import MediaGenres from "../core/MediaGenres";
import Title from "../../../core/title/Title";
import BaseHeader from "../core/header/BaseHeader";

export interface MovieHeaderProps {
    movie: MovieDetail
}

export default function MovieHeader({movie}: MovieHeaderProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasVote = movie?.voteAverage !== undefined && movie?.voteCount !== undefined && movie.voteCount > 0;
    const hasGenres = movie?.genres !== undefined && movie.genres.length > 0;

    const subDetailArray = [];
    if (hasVote) subDetailArray.push(<MediaVote key={uuidv4()} voteAverage={movie.voteAverage}
                                                voteCount={movie.voteCount}/>);
    if (hasGenres) subDetailArray.push(<MediaGenres key={uuidv4()} genres={movie.genres}/>);
    subDetailArray.push(<MediaDuration key={uuidv4()} runtime={movie.runtime}/>);
    const separatedSubDetailArray = subDetailArray.flatMap((child, index) => {
        if (index === subDetailArray.length - 1) {
            return child;
        } else {
            return [child, <span key={uuidv4()}>|</span>];
        }
    });

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const headerContent = <Box margin={2}>
        <Box display={"flex"} gap={2}>
            <Typography variant="h4" style={{fontWeight: 'bold'}}>{movie.title}</Typography>
            {movie?.year && <Typography variant="h4">({movie.year})</Typography>}
        </Box>
        <Box display={"flex"} gap={2}>
            {separatedSubDetailArray}
        </Box>
        {movie.overview && <Box>
            <Box marginTop={2}/>
            <Title label={'Résumé'}/>
            <Typography style={{marginTop: 5}}>{movie.overview}</Typography>
        </Box>}
    </Box>

    return <BaseHeader headerContent={headerContent} posterImage={movie.imagePath} backdropImage={movie.backdropPath}/>
}