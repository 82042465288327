import {Box, Button} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {MovieDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaMovieService from "../../../../../domain/service/media/detail/movie/MediaMovieService";
import DownloadRequestService from "../../../../../domain/service/download/request/DownloadRequestService";
import MediaDownloadStatusChip from "../../../core/media/chip/MediaDownloadStatusChip";
import MediaMovieRequestButton from "../../request/MediaMovieRequestButton";
import DeleteIcon from '@mui/icons-material/Delete';

export interface MovieAvailabilityDetailProps {
    movie: MovieDetail
}

export default function MovieAvailabilityDetail({movie}: MovieAvailabilityDetailProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [statusMovie, setStatusMovie] = useState<MovieDetail>();
    const refreshIntervalRef = useRef<number | null>(null);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const mediaMovieService = MediaMovieService;
    const downloadRequestService = DownloadRequestService;
    const existsRequest = statusMovie?.requestStatus !== undefined;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const refreshMovie = useCallback(async () => {

        mediaMovieService.fetchOne(movie.id)
            .then((response => {
                setStatusMovie(response.data);
            }));
    }, [movie, mediaMovieService]);

    const deleteRequest = useCallback(async () => {

        downloadRequestService.deleteMovieRequest(movie.id)
            .then((response => {
                refreshMovie();
            }));
    }, [movie, downloadRequestService, refreshMovie]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const onRequestCreated = () => {
        refreshMovie();
    }

    const onDeleteRequestClick = () => {
        deleteRequest();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        setStatusMovie(movie);
        refreshIntervalRef.current = window.setInterval(refreshMovie, 1000);
        return () => {
            if (refreshIntervalRef.current !== null) {
                clearInterval(refreshIntervalRef.current);
            }
        };
    }, [movie, refreshMovie]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box margin={2} display={"flex"} gap={1}>
        {statusMovie &&
            <MediaDownloadStatusChip chipType={"chip"} mediaType={"MOVIE"} availability={statusMovie.availability}
                                     requestStatus={statusMovie.requestStatus}/>}
        {(!existsRequest) && <MediaMovieRequestButton idMovie={movie.id} onRequestCreated={onRequestCreated}/>}
        {existsRequest &&
            <Button onClick={onDeleteRequestClick} variant={"outlined"} color={"error"} startIcon={<DeleteIcon/>}>
                Supprimer la requête
            </Button>
        }
    </Box>
}