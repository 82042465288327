import axios from "axios";
import User from "../../commons/model/user/User";
import {Constants} from "../../commons/constants/Constants";

const fetchMe = () => axios.get<User>("/api/users/me");
const logout = () => axios.post("/logout").then(() => {
    localStorage.removeItem(Constants.localStorage.plexToken);
    window.location.reload();
});

const UserService = {
    fetchMe,
    logout
}

export default UserService;