import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {PlexLibrary} from "../../../../domain/commons/model/plex/PlexModels";
import PlexService from "../../../../domain/service/plex/PlexService";
import {Box, Button, InputLabel} from "@mui/material";
import PlexLibrarySettingInput from "../../../component/setting/library/PlexLibrarySettingInput";
import {uuidv4} from "../../../../domain/commons/utils/UUIDProvider";

export interface PlexSettingLibrarySelectorProps {
    onLibrariesSelected: () => void,
    disabled?: boolean
}

export default function PlexSettingLibrarySelector({
                                                       onLibrariesSelected,
                                                       disabled
                                                   }: PlexSettingLibrarySelectorProps) {
    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [libraries, setLibraries] = useState<PlexLibrary[] | undefined>();

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const plexService = PlexService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const loadLibraries = useCallback(async () => {

        const result = await plexService.fetchLibraries();
        setLibraries(result.data);

    }, [plexService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleChange = () => {

        loadLibraries();
    };

    const handleNext = () => {

        onLibrariesSelected();
    };

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        loadLibraries();

    }, [loadLibraries]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <Box marginTop={1}>
            <InputLabel variant="standard">
                Configurer les bibliothèques
            </InputLabel>
            {libraries && libraries.map((library) =>
                <PlexLibrarySettingInput library={library} key={uuidv4()} onUpdate={handleChange} disabled={disabled}/>
            )}
        </Box>
        <Box marginTop={2}>
            <Button disabled={disabled} color={"primary"} variant={"outlined"} onClick={handleNext}>Lancer la synchronisation</Button>
        </Box>
    </Box>
}