import axios from "axios";
import {MediaPageSize, PaginatedResult} from "../../../commons/model/pagination/PaginationModels";
import {SearchResult} from "../../../commons/model/media/MediaSearchModels";

const search = (query: string, pageSize: MediaPageSize, page: number) => axios.get<PaginatedResult<SearchResult>>(`/api/medias/search`, {
    params: {
        query: query,
        pageSize: pageSize,
        page: page
    }
});

const MediaSearchService = {
    search
}

export default MediaSearchService;