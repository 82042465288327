import {Card, CardMedia} from "@mui/material";

export interface YoutubeChannelHeaderPosterProps {
    imagePath?: string
}

export default function YoutubeChannelHeaderPoster({imagePath}: YoutubeChannelHeaderPosterProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/


    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Card sx={{borderRadius: '12px', width: 150, margin: 1, flexShrink: 0, border: '1px solid #313A47'}}>
        <CardMedia component="img" image={imagePath} referrerPolicy={"no-referrer"}/>
    </Card>
}