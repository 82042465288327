import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {PlexServer, PlexServerOption} from "../../../../domain/commons/model/plex/PlexModels";
import PlexService from "../../../../domain/service/plex/PlexService";
import {Box, Button, InputLabel, MenuItem, Select} from "@mui/material";
import {uuidv4} from "../../../../domain/commons/utils/UUIDProvider";

export interface PlexSettingServerSelectorProps {
    onServerSelected: () => void
    onServerAllReadyConfigured: () => void
}

export default function PlexSettingServerSelector({
                                                      onServerSelected,
                                                      onServerAllReadyConfigured
                                                  }: PlexSettingServerSelectorProps) {
    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [servers, setServers] = useState<PlexServer[] | undefined>();
    const [serverUrl, setServerUrl] = useState("");

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const plexService = PlexService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const validateSettings = useCallback(async () => {

        const plexSettings = await plexService.fetchSettings();
        if (plexSettings.data.currentServer === undefined) {
            setServers(plexSettings.data.servers);
        } else {

            onServerAllReadyConfigured();
        }

    }, [plexService, onServerAllReadyConfigured]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const generateServerOptions = (): PlexServerOption[] => {

        const serverOptions: PlexServerOption[] = [];

        servers?.forEach(server => {
            server.connections.forEach(connection => {
                serverOptions.push({key: connection.url, value: `${server.name} (${connection.url})`})
            })
        })

        return serverOptions;
    }

    const handleChange = (key: string) => {

        setServerUrl(key);
    };

    const handleNext = () => {

        plexService.updatePlexServerUrl(serverUrl)
            .then((response) => {
                if (response.status === 200) {
                    onServerSelected();
                }
            })
    };

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        validateSettings();

    }, [validateSettings]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const options = generateServerOptions().map((option) =>
        <MenuItem key={uuidv4()} value={option.key}>{option.value}</MenuItem>
    );

    return <Box>
        <Box marginTop={1}>
            <InputLabel variant="standard">
                Sélectionner un serveur
            </InputLabel>
            <Select
                value={serverUrl}
                style={{minWidth: 400}}
                onChange={(event) => handleChange(event.target.value)}
            >
                {options}
            </Select>
        </Box>
        <Box marginTop={1}>
            <Button color={"primary"} variant={"outlined"} disabled={!serverUrl}
                    onClick={handleNext}>Suivant</Button>
        </Box>
    </Box>
}