import {Constants} from "../../../../../../domain/commons/constants/Constants";
import {Card, CardMedia} from "@mui/material";

export interface DetailHeaderPosterProps {
    posterImage?: string
}

export default function DetailHeaderPoster({posterImage}: DetailHeaderPosterProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const finalPosterImage = posterImage !== undefined ? Constants.assets.posterBaseUrl + posterImage : Constants.assets.noPosterUrl;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Card sx={{width: '100%', border: '1px solid #313A47', borderRadius: '12px'}}>
        <CardMedia component="img" image={finalPosterImage}/>
    </Card>
}