import axios from "axios";
import {MovieDetail} from "../../../commons/model/media/MediaDetailModels";

const createMovieRequest = (id: number, idPlexLibrary: number) => axios.post(`/api/requests/movies/${id}`, null, {
    params: {
        "idPlexLibrary": idPlexLibrary
    }
});
const createTvRequest = (id: number, idPlexLibrary: number) => axios.post(`/api/requests/tvs/${id}`, null, {
    params: {
        "idPlexLibrary": idPlexLibrary
    }
});
const deleteMovieRequest = (id: number) => axios.delete<MovieDetail>(`/api/requests/movies/${id}`);
const deleteTvRequest = (id: number) => axios.delete<MovieDetail>(`/api/requests/tvs/${id}`);

const DownloadRequestService = {
    createMovieRequest,
    createTvRequest,
    deleteMovieRequest,
    deleteTvRequest
}

export default DownloadRequestService;