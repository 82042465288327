import {Box} from "@mui/material";
import MediaSkeletonCard from "../../card/skeleton/MediaSkeletonCard";
import Title from "../../../title/Title";
import {uuidv4} from "../../../../../../domain/commons/utils/UUIDProvider";

export interface MediaSearchResultCardContainerProps {
    title: string
}

export default function MediaSkeletonCardContainer({title}: MediaSearchResultCardContainerProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <Title label={title}/>
        <Box sx={{display: 'flex', flexWrap: "wrap"}}>
            {Array.from({length: 10}).map(() => (
                <MediaSkeletonCard key={uuidv4()}/>
            ))}
        </Box>
    </Box>
}