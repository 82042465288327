import {Box, Button} from "@mui/material";
import {useCallback, useState} from "react";
import MediaProfileSelectorDialog from "./dialog/MediaProfileSelectorDialog";
import DownloadIcon from '@mui/icons-material/Download';
import DownloadRequestService from "../../../../domain/service/download/request/DownloadRequestService";

export interface MediaMovieRequestButtonProps {
    idMovie: number
    onRequestCreated: () => void
}

export default function MediaMovieRequestButton({idMovie, onRequestCreated}: MediaMovieRequestButtonProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [modalOpen, setModalOpen] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const downloadRequestService = DownloadRequestService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const createRequest = useCallback(async (idPlexLibrary: number) => {

        downloadRequestService.createMovieRequest(idMovie, idPlexLibrary)
            .then((response => {
                if (response.status === 201) {
                    onRequestCreated();
                }
            }));

    }, [idMovie, downloadRequestService, onRequestCreated]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const onClick = () => {
        setModalOpen(true);
    }

    const onModalClose = () => {
        setModalOpen(false);
    }

    const onDownloadProfileSelected = (idPlexLibrary: number) => {

        createRequest(idPlexLibrary);
    }

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <Button onClick={onClick} variant={"outlined"} startIcon={<DownloadIcon/>}>Demander</Button>
        <MediaProfileSelectorDialog title="Demander le film" mediaType={"MOVIE"} open={modalOpen} onClose={onModalClose}
                                    onDownloadProfileSelected={onDownloadProfileSelected}/>
    </Box>
}