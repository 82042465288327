import {Box, Chip} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import {DownloadRequestStatus, MediaAvailability} from "../../../../../domain/commons/model/media/MediaCoreModels";

export type ChipType = 'icon' | 'chip';

export interface AvailabilityStatusChipProps {
    chipType: ChipType,
    availability?: MediaAvailability
    requestStatus?: DownloadRequestStatus
}

export default function AvailabilityStatusChip({chipType, availability, requestStatus}: AvailabilityStatusChipProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    let state: string;
    if(availability !== undefined && availability !== 'NOT_AVAILABLE' && requestStatus !== 'PROCESSING') {
        state = availability;
    } else if(requestStatus !== undefined) {
        state = requestStatus;
    } else {
        state = 'NOT_AVAILABLE';
    }

    let icon;
    let chip;
    switch (state) {
        case 'ASKED':
            icon = <PlaylistAddCheckCircleIcon color={"action"}/>
            chip = <Chip color={"default"} label={"Demandé"}/>
            break;
        case 'PROCESSING':
            icon = <ChangeCircleIcon color={"info"}/>
            chip = <Chip color={"info"} label={"En cours"}/>
            break;
        case 'PARTIALLY_AVAILABLE':
            icon = <RemoveCircleIcon sx={{color: 'orange'}}/>
            chip = <Chip sx={{backgroundColor: 'orange', color: 'black'}} label={"Partiellement disponible"}/>
            break;
        case 'AVAILABLE':
            icon = <CheckCircleIcon color={"success"}/>
            chip = <Chip color={"success"} label={"Disponible"}/>
            break;
        default:
            icon = <Box/>;
            chip = <Chip color={"error"} label={"Indisponible"}/>
    }

    let component;
    switch (chipType) {
        case "chip":
            component = <Box marginBottom={1}>{chip}</Box>
            break;
        case "icon":
            component = <Box sx={{
                position: 'absolute',
                top: 5,
                right: 5,
                zIndex: 1
            }}>
                {icon}
            </Box>
            break;
        default:
            component = <Box/>
    }

    return <Box>
        {component}
    </Box>

}