import {Constants} from "../../../../../../domain/commons/constants/Constants";
import {Box} from "@mui/material";

export interface DetailHeaderBackdropProps {
    backdropImage?: string
}

export default function DetailHeaderBackdrop({backdropImage}: DetailHeaderBackdropProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const finalBackdropImage = backdropImage !== undefined ? Constants.assets.backdropBaseUrl + backdropImage : undefined;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (finalBackdropImage) {
        const backdropUrl = "linear-gradient(rgba(17, 24, 39, 0.47) 0%, rgb(17, 24, 39) 100%), url(" + finalBackdropImage + ")";

        return <Box style={{
            height: 450,
            backgroundImage: backdropUrl,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: -1
        }}/>
    } else {
        return <Box/>
    }
}