import {Box, CircularProgress} from "@mui/material";
import * as React from "react";
import {useCallback, useState} from "react";
import Title from "../../../component/core/title/Title";
import PlexSettingServerSelector from "./PlexSettingServerSelector";
import PlexSettingLibrarySelector from "./PlexSettingLibrarySelector";
import Typography from "@mui/material/Typography";
import MediaSynchronizationService from "../../../../domain/service/media/synchronization/MediaSynchronizationService";

export interface PlexSettingPageProps {
    onSettingSuccess: () => void
}

export default function PlexSettingPage({onSettingSuccess}: PlexSettingPageProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [step, setStep] = useState('serverSelection');
    const [syncLoading, setSyncLoading] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    let mediaSynchronizationService = MediaSynchronizationService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const synchronize = useCallback(async () => {
        setSyncLoading(true);
        mediaSynchronizationService.synchronizes()
            .then((response) => {
                if (response.status === 200) {
                    onSettingSuccess();
                }
            })
            .finally(() => {
                setSyncLoading(false);
            })
    }, [mediaSynchronizationService, onSettingSuccess]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/


    const onServerSelected = () => {
        setStep('librarySelection');
    };

    const onServerAllReadyConfigured = () => {
        onSettingSuccess();
    };

    const onLibrariesSelected = () => {
        setStep('synchronization');
        synchronize();
    };

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }}>
        <Title label={"Configuration du serveur PLEX"}/>
        {step === 'serverSelection' && <PlexSettingServerSelector onServerSelected={onServerSelected}
                                                                  onServerAllReadyConfigured={onServerAllReadyConfigured}/>}
        {step === 'librarySelection' && <PlexSettingLibrarySelector onLibrariesSelected={onLibrariesSelected} disabled={syncLoading}/>}

        {syncLoading && <Box marginTop={1} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress/> <Typography> Synchronisation en cours</Typography>
        </Box>}

    </Box>
}