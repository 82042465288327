import {Button, Card, CardMedia} from "@mui/material";
import {MediaProvider} from "../../../../../domain/commons/model/media/MediaCoreModels";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";

export interface MediaProviderCardProps {
    provider: MediaProvider
}

export default function MediaProviderCard({provider}: MediaProviderCardProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const componentRef = useRef<HTMLDivElement | null>(null);
    const [showDetail, setShowDetail] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    let posterImage;
    let providerRoute;
    switch (provider) {
        case "NETFLIX":
            posterImage = "https://image.tmdb.org/t/p/w780_filter(duotone,ffffff,bababa)/wwemzKWzjKYJFfCeiB57q3r4Bcm.png";
            providerRoute = "netflix";
            break;
        case "AMAZON_PRIME_VIDEO":
            posterImage = "https://image.tmdb.org/t/p/w780_filter(duotone,ffffff,bababa)/ifhbNuuVnlwYy5oXA5VIb2YR8AZ.png";
            providerRoute = "amazon";
            break;
        case "DISNEY_PLUS":
            posterImage = "https://image.tmdb.org/t/p/w780_filter(duotone,ffffff,bababa)/gJ8VX6JSu3ciXHuC2dDGAo2lvwM.png";
            providerRoute = "disney";
            break;
        case "APPLE_TV_PLUS":
            posterImage = "https://image.tmdb.org/t/p/w780_filter(duotone,ffffff,bababa)/4KAy34EHvRM25Ih8wb82AuGU7zJ.png";
            providerRoute = "apple";
            break;
        default:
            posterImage = "https://image.tmdb.org/t/p/w780_filter(duotone,ffffff,bababa)/wwemzKWzjKYJFfCeiB57q3r4Bcm.png";
            providerRoute = "netflix";
    }

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onMouseEnter() {
        setShowDetail(true);
    }

    function onMouseLeave() {
        setShowDetail(false);
    }

    function onClickOutside(event: MouseEvent) {
        if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
            setShowDetail(false);
        }
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);

        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, []);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Card
        sx={{
            borderRadius: '12px',
            width: 200,
            height: 120,
            padding: 1,
            margin: 1,
            border: '1px solid #313A47',
            display: "flex",
            alignItems: "center",
            position: "relative"
        }}
        className={"mediaCard"}
        onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
    >
        <CardMedia component="img" image={posterImage}/>
        {showDetail && <Link to={`/medias/discover/movies/${providerRoute}`}>
            <Button size={"small"} variant={"contained"} sx={{position: "absolute", bottom: 5, left: 5}}>Films</Button>
        </Link>}
        {showDetail && <Link to={`/medias/discover/tvs/${providerRoute}`}>
            <Button size={"small"} variant={"contained"}
                    sx={{position: "absolute", bottom: 5, right: 5}}>Séries</Button>
        </Link>}
    </Card>
}