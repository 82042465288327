import * as React from "react";
import {Box, InputLabel, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {uuidv4} from "../../../../domain/commons/utils/UUIDProvider";

export interface ToggleSelectorOption {
    value: string
    label: string
}

export interface ToggleSelectorProps {
    label: string
    values: ToggleSelectorOption[]
    selected: string
    setSelected: (value: string) => void
}

export default function ToggleSelector({label, values, selected, setSelected}: ToggleSelectorProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleChange = (value: string) => {
        setSelected(value);
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <InputLabel variant="standard">
            {label}
        </InputLabel>
        <ToggleButtonGroup
            color="primary"
            value={selected}
            exclusive
            onChange={(event, value) => handleChange(value)}
        >
            {values.map((option => <ToggleButton value={option.value} key={uuidv4()}>{option.label}</ToggleButton>))}
        </ToggleButtonGroup>
    </Box>
}