import {TvSeasonDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaReleaseCardContainer from "../../../core/media/container/release/MediaReleaseCardContainer";
import {useCallback, useEffect, useState} from "react";
import {MediaRelease} from "../../../../../domain/commons/model/media/MediaReleaseModels";
import DownloadReleaseService from "../../../../../domain/service/download/release/DownloadReleaseService";
import LoadingAlert from "../../../core/alert/LoadingAlert";
import MediaSkeletonReleaseCardContainer
    from "../../../core/media/container/skeleton/MediaSkeletonReleaseCardContainer";
import {Box} from "@mui/material";
import MediaProfileSelectorDialog from "../../request/dialog/MediaProfileSelectorDialog";
import {useNavigate} from "react-router-dom";

export interface TvSeasonReleaseProps {
    season: TvSeasonDetail
}

export default function TvSeasonRelease({season}: TvSeasonReleaseProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [releases, setReleases] = useState<MediaRelease[]>();
    const [release, setRelease] = useState<MediaRelease>();
    const [showLibrarySelector, setShowLibrarySelector] = useState(false);
    const navigate = useNavigate();

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const downloadReleaseService = DownloadReleaseService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchReleases = useCallback(async () => {

        setLoading(true);
        downloadReleaseService.findAllTvSeasonReleases(season.tv.id, season.seasonNumber, true)
            .then((response => {
                setShowServerError(false);
                setReleases(response.data);
            }))
            .catch(onServerError)
            .finally(() => setLoading(false));

    }, [downloadReleaseService, season.tv.id, season.seasonNumber]);

    const downloadRelease = useCallback(async (idPlexLibrary: number) => {

        if (release?.url) {
            setLoading(true);
            downloadReleaseService.downloadTvSeason(season.tv.id, season.seasonNumber, idPlexLibrary, release?.url)
                .then((response => {
                    setShowServerError(false);
                    navigate(`/medias/detail/tvs/${season.tv.id}`);
                }))
                .catch(onServerError)
                .finally(() => setLoading(false));
        }

    }, [downloadReleaseService, season.tv.id, season.seasonNumber, release?.url,navigate]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchReleases();
    }

    function onReleaseDownloadClick(release: MediaRelease) {
        setRelease(release);
        setShowLibrarySelector(true);
    }

    function onProfileSelectorClose() {
        setRelease(undefined);
        setShowLibrarySelector(false);
    }

    function onDownloadProfileSelected(idQualityProfile: number) {

        downloadRelease(idQualityProfile);
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchReleases();
    }, [fetchReleases]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (loading) {
        return <MediaSkeletonReleaseCardContainer title={"Releases"}/>
    } else if (showServerError || releases === undefined) {
        return <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        return <Box>
            <MediaProfileSelectorDialog title={"Télécharger la release"} mediaType={"TV"} open={showLibrarySelector}
                                        onClose={onProfileSelectorClose}
                                        onDownloadProfileSelected={onDownloadProfileSelected}/>
            <MediaReleaseCardContainer onReleaseDownloadClick={onReleaseDownloadClick} title={"Releases de la saison"}
                                       data={releases}/>
        </Box>
    }
}