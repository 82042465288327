import {DownloadRequestStatus, MediaAvailability} from "./MediaCoreModels";

export type MediaType = 'TV' | 'MOVIE' | 'PERSON' | 'COLLECTION';

export interface SearchResult {
    id: number
    mediaType: MediaType
    imagePath?: string
    availability?: MediaAvailability
    requestStatus?: DownloadRequestStatus
}

export interface MediaResult extends SearchResult {
    title: string;
    year: number;
    character?: string;
    job?: string;
    backdropPath?: string;
}

export interface MovieResult extends MediaResult {
    mediaType: 'MOVIE';
}

export interface TvResult extends MediaResult {
    mediaType: 'TV';
}

export interface PersonResult extends SearchResult {
    mediaType: 'PERSON';
    name: string;
    role?: string;
}

export function sortMediasByYear(data: MediaResult[], reverse?: boolean): MediaResult[] {
    return data.sort((a, b) => {
        if (a.year === undefined && b.year === undefined) {
            return 0;
        } else if (a.year === undefined) {
            return 1;
        } else if (b.year === undefined) {
            return -1;
        } else {
            return reverse ? b.year - a.year : a.year - b.year;
        }
    })
}