import {Alert, Box, IconButton} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import * as React from "react";

export interface LoadingAlertProps {
    width?: string,
    onClick?: () => void
}

export default function LoadingAlert({onClick, width}: LoadingAlertProps) {

    return <Box sx={{marginTop: 2, marginBottom: 2, width: width}}><Alert sx={{width: width}} severity="error" action={onClick &&
        <IconButton color="inherit" size="small"><ReplayIcon/></IconButton>}
                                           onClick={onClick}> Erreur de chargement</Alert></Box>
};