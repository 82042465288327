import {Box, Typography} from "@mui/material";
import BaseHeader from "../core/header/BaseHeader";
import Title from "../../../core/title/Title";
import {CollectionDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";

export interface CollectionHeaderProps {
    collection: CollectionDetail
}

export default function CollectionHeader({collection}: CollectionHeaderProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const headerContent = <Box margin={2}>
        <Box display={"flex"} gap={2}>
            <Typography variant="h4" style={{fontWeight: 'bold'}}>{collection.title}</Typography>
        </Box>
        <Box display={"flex"} gap={2}>

        </Box>
        <Box marginTop={2}/>
        <Title label={'Résumé'}/>
        <Typography style={{marginTop: 5}}>{collection.overview}</Typography>
    </Box>

    return <BaseHeader headerContent={headerContent} posterImage={collection.imagePath}
                       backdropImage={collection.backdropPath}/>
}