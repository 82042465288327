import {Box, Typography} from "@mui/material";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import {TvSeasonDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaVote from "../core/MediaVote";
import Title from "../../../core/title/Title";
import BaseHeader from "../core/header/BaseHeader";

export interface TvSeasonHeaderProps {
    season: TvSeasonDetail
}

export default function TvSeasonHeader({season}: TvSeasonHeaderProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasVote = season?.voteAverage !== undefined;
    const subDetailArray = [];
    if (hasVote) subDetailArray.push(<MediaVote key={uuidv4()} voteAverage={season.voteAverage} voteCount={1}/>);
    const separatedSubDetailArray = subDetailArray.flatMap((child, index) => {
        if (index === subDetailArray.length - 1) {
            return child;
        } else {
            return [child, <span key={uuidv4()}>|</span>];
        }
    });

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const headerContent = <Box margin={2}>
        <Box display={"flex"} gap={2}>
            <Typography variant="h4" style={{fontWeight: 'bold'}}>{season.tv.title}</Typography>
            {season.tv?.year && <Typography variant="h4">({season.tv.year})</Typography>}
        </Box>
        <Typography variant="h5" style={{fontWeight: 'bold', marginBottom: 2}}>{season.title}</Typography>
        <Box display={"flex"} gap={2}>
            {separatedSubDetailArray}
        </Box>
        {season.overview && <Box>
            <Box marginTop={2}/>
            <Title label={'Résumé'}/>
            <Typography style={{marginTop: 5}}>{season.overview}</Typography>
        </Box>}
    </Box>

    return <BaseHeader headerContent={headerContent} posterImage={season.imagePath}
                       backdropImage={season.tv.backdropPath}/>
}