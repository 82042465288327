import {Box} from "@mui/material";
import {DownloadRequestStatus, MediaAvailability} from "../../../../../domain/commons/model/media/MediaCoreModels";
import {MediaType} from "../../../../../domain/commons/model/media/MediaSearchModels";
import AvailabilityStatusChip from "./AvailabilityStatusChip";

export type ChipType = 'icon' | 'chip';

export interface MediaDownloadStatusChipProps {
    chipType: ChipType,
    mediaType?: MediaType,
    availability?: MediaAvailability
    requestStatus?: DownloadRequestStatus
}

export default function MediaDownloadStatusChip({
                                                    chipType,
                                                    mediaType,
                                                    availability,
                                                    requestStatus
                                                }: MediaDownloadStatusChipProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if ((mediaType === 'MOVIE' || mediaType === 'TV')) {
        return <Box>
            <AvailabilityStatusChip availability={availability} requestStatus={requestStatus} chipType={chipType}/>
        </Box>
    } else {
        return <Box/>
    }

}