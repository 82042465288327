import {Card, Skeleton} from "@mui/material";

export default function YoutubeChannelSkeletonCard() {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Card sx={{maxWidth: 150, margin: 1, borderRadius: '12px'}}>
        <Skeleton variant="rectangular" width={150} height={150}/>
    </Card>
}