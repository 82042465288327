import {Box, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {YoutubeChannelDetail} from "../../../../../domain/commons/model/youtube/YoutubeModels";
import YoutubeChannelHeaderBackdrop from "./YoutubeChannelHeaderBackdrop";
import YoutubeChannelHeaderPoster from "./YoutubeChannelHeaderPoster";
import FormattedTextBox from "../../../core/text/FormattedTextBox";

export interface YoutubeChannelHeaderProps {
    channel: YoutubeChannelDetail,
    withLink?: boolean
}

export default function YoutubeChannelHeader({channel, withLink}: YoutubeChannelHeaderProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const titleCpt = <Typography variant="h4" style={{fontWeight: 'bold'}}>{channel.title}</Typography>;
    let title;
    if (withLink !== undefined && withLink) {
        title = <Link to={"/youtube/channels/" + channel.id} style={{textDecoration: "none", color: "white"}}>
            {titleCpt}
        </Link>
    } else {
        title = titleCpt;
    }

    const headerContent = <Box margin={2}>
        <Box display={"flex"} gap={2}>
            {title}
        </Box>
        {channel.overview && <FormattedTextBox text={channel.overview} maxHeaderLines={1}/>}
    </Box>

    return <Box>
        <YoutubeChannelHeaderBackdrop backdropPath={channel.backdropPath}/>
        <Box margin={2}>
            <Grid container spacing={3}>
                {/* Poster */}
                <Grid item width={175}>
                    <YoutubeChannelHeaderPoster imagePath={channel.imagePath}/>
                </Grid>
                {/* Content */}
                <Grid item xs={12} md={8} lg={6}>
                    {headerContent}
                </Grid>
            </Grid>
        </Box>
    </Box>
}