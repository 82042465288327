import {Box, Grid} from "@mui/material";
import DetailHeaderBackdrop from "./DetailHeaderBackdrop";
import DetailHeaderPoster from "./DetailHeaderPoster";

export interface BaseHeaderProps {
    headerContent: JSX.Element,
    posterImage?: string,
    backdropImage?: string
}

export default function BaseHeader({headerContent, posterImage, backdropImage}: BaseHeaderProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box>
        <DetailHeaderBackdrop backdropImage={backdropImage}/>
        <Box margin={2}>
            <Grid container spacing={3}>
                {/* Poster */}
                <Grid item width={250}>
                    <DetailHeaderPoster posterImage={posterImage}/>
                </Grid>
                {/* Content */}
                <Grid item xs={12} md={8} lg={6}>
                    {headerContent}
                </Grid>
            </Grid>
        </Box>
    </Box>
}