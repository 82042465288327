import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {MovieDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import MediaMovieService from "../../../../../domain/service/media/detail/movie/MediaMovieService";
import MediaHeaderSkeleton from "../../../../component/media/detail/core/header/skeleton/MediaHeaderSkeleton";
import MovieDetailContainer from "../../../../component/media/detail/movie/MovieDetailContainer";
import LoadingAlert from "../../../../component/core/alert/LoadingAlert";

export default function MediaMovieDetailPage() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [movie, setMovie] = useState<MovieDetail>();

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const mediaMovieService = MediaMovieService;
    const {movieId} = useParams();

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchMovie = useCallback(async () => {

        const id = movieId ? +movieId : 0;
        setLoading(true);
        mediaMovieService.fetchOne(id)
            .then((response => {
                setShowServerError(false);
                setMovie(response.data);
            }))
            .catch(onServerError)
            .finally(() => setLoading(false));

    }, [movieId, mediaMovieService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchMovie();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchMovie();
    }, [fetchMovie]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (loading) {
        return <MediaHeaderSkeleton/>
    } else if (showServerError || movie === undefined) {
        return <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        return <MovieDetailContainer movie={movie}/>
    }
}