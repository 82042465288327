import {Box} from "@mui/material";
import * as React from "react";
import YoutubeDiscoverSyncedChannels from "../../../component/youtube/discover/YoutubeDiscoverSyncedChannels";

export default function YoutubeDiscoverPage() {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box sx={{margin: 2}}>

        <YoutubeDiscoverSyncedChannels/>
    </Box>
}