import {Box, Card, CardContent, CardMedia, IconButton, Tooltip, Typography} from "@mui/material";
import {TvEpisode} from "../../../../../domain/commons/model/media/MediaCoreModels";
import {useMQ} from "../../../../../theme";
import {Constants} from "../../../../../domain/commons/constants/Constants";
import MediaVote from "../../../media/detail/core/MediaVote";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import MediaDuration from "../../../media/detail/core/MediaDuration";
import MediaTvEpisodeAirDateChip from "../chip/MediaTvEpisodeAirDateChip";
import AvailabilityStatusChip from "../chip/AvailabilityStatusChip";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export interface MediaTvEpisodeCardProps {
    episode: TvEpisode
    onReleaseClick: (episode: TvEpisode) => void
}

export default function MediaTvEpisodeCard({episode, onReleaseClick}: MediaTvEpisodeCardProps) {


    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const isMobile = useMQ().isMobile;
    const cardDisplay = isMobile ? 'block' : 'flex';
    const finalPosterImage = episode.imagePath !== undefined ? Constants.assets.episodeBaseUrl + episode.imagePath : Constants.assets.noPosterUrl;
    const hasVote = episode?.voteAverage !== undefined && episode?.voteCount !== undefined && episode.voteCount > 0;

    const subDetailArray = [];
    if (hasVote) subDetailArray.push(<MediaVote key={uuidv4()} voteAverage={episode.voteAverage}
                                                voteCount={episode.voteCount}/>);
    subDetailArray.push(<MediaDuration key={uuidv4()} runtime={episode.runtime}/>);
    const separatedSubDetailArray = subDetailArray.flatMap((child, index) => {
        if (index === subDetailArray.length - 1) {
            return child;
        } else {
            return [child, <span key={uuidv4()}>|</span>];
        }
    });

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Card sx={{display: cardDisplay, borderRadius: '12px', position: 'relative'}}>
        {episode?.availability && <AvailabilityStatusChip availability={episode.availability} chipType={"icon"}/>}
        {episode?.airDate && <MediaTvEpisodeAirDateChip airDate={episode.airDate}/>}
        <Tooltip title="Voir les releases" placement={"left"}>
            <IconButton onClick={() => onReleaseClick(episode)} sx={{position: 'absolute', bottom: 2, right: 10}}>
                <CloudDownloadIcon/>
            </IconButton>
        </Tooltip>
        <CardMedia
            component="img"
            sx={{width: 227, height: 127}}
            image={finalPosterImage}
        />
        <CardContent>
            <Typography component="div" variant="h5">
                {episode.episodeNumber + '. ' + episode.title}
            </Typography>
            <Box display={"flex"} gap={2}>
                {separatedSubDetailArray}
            </Box>
            <Typography variant="subtitle1" color="text.secondary" component="div">
                {episode.overview}
            </Typography>
        </CardContent>
    </Card>
}