import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import MediaDiscoverService from "../../../../../domain/service/media/discover/MediaDiscoverService";
import {SearchResult} from "../../../../../domain/commons/model/media/MediaSearchModels";
import MediaSkeletonCardContainer from "../../../../component/core/media/container/skeleton/MediaSkeletonCardContainer";
import LoadingAlert from "../../../../component/core/alert/LoadingAlert";
import MediaSearchResultCardContainer from "../../../../component/core/media/container/MediaSearchResultCardContainer";
import {MediaSort, MediaProvider} from "../../../../../domain/commons/model/media/MediaCoreModels";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarsIcon from '@mui/icons-material/Stars';

export interface MediaDiscoverProviderTvsPageProps {
    provider: string
}

export default function MediaDiscoverProviderTvsPage({provider}: MediaDiscoverProviderTvsPageProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [data, setData] = useState<SearchResult[]>([]);
    const [sort, setSort] = useState<MediaSort>("RELEASE_DATE_DESC");

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    let title;
    let remoteProvider: MediaProvider;
    switch (provider) {
        case "NETFLIX":
            title = "Netflix";
            remoteProvider = "NETFLIX";
            break;
        case "AMAZON_PRIME_VIDEO":
            title = "Amazon Prime Video";
            remoteProvider = "AMAZON_PRIME_VIDEO";
            break;
        case "DISNEY_PLUS":
            title = "Disney +";
            remoteProvider = "DISNEY_PLUS";
            break;
        case "APPLE_TV_PLUS":
            title = "Apple TV +";
            remoteProvider = "APPLE_TV_PLUS";
            break;
        default:
            remoteProvider = "NETFLIX";
    }

    const discoverService = MediaDiscoverService;
    const sliderTitle = `Séries ${title}`;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchData = useCallback(async () => {

        setLoading(true);
        discoverService.providerTvs("LARGE", 1, remoteProvider, sort)
            .then((response => {
                setShowServerError(false);
                setData(response.data.results);
            })).catch(onServerError)
            .finally(() => setLoading(false));

    }, [discoverService, remoteProvider, sort]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {

        fetchData();
    }

    const handleChangeSort = (value: string) => {
        switch (value) {
            case 'RELEASE_DATE_DESC':
                setSort("RELEASE_DATE_DESC");
                break;
            case 'POPULARITY_DESC':
                setSort("POPULARITY_DESC");
                break;
        }
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchData();
    }, [fetchData]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    let content;
    if (loading) {
        content = <MediaSkeletonCardContainer title={sliderTitle}/>
    } else if (showServerError) {
        content = <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        content = <MediaSearchResultCardContainer title={sliderTitle} data={data}/>
    }

    return <Box sx={{margin: 2}}>
        <ToggleButtonGroup
            color="primary"
            value={sort}
            exclusive
            onChange={(event, value) => handleChangeSort(value)}
        >
            <ToggleButton value={"RELEASE_DATE_DESC"} key={uuidv4()}><AccessTimeIcon sx={{marginRight: 1}}/>{"Récents"}
            </ToggleButton>
            <ToggleButton value={"POPULARITY_DESC"} key={uuidv4()}><StarsIcon sx={{marginRight: 1}}/>{"Populaires"}
            </ToggleButton>
        </ToggleButtonGroup>
        <Box margin={2}/>
        {content}
    </Box>
}