import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Box, Drawer, TextField} from "@mui/material";
import {MediaDetail} from "../../../../domain/commons/model/media/MediaDetailModels";
import {AlternateTitle} from "../../../../domain/commons/model/download/DownloadModels";
import AlternateTitleService from "../../../../domain/service/download/alternate_title/AlternateTitleService";
import AlternateTitleCard from "../../core/media/card/alternate_title/AlternateTitleCard";
import {uuidv4} from "../../../../domain/commons/utils/UUIDProvider";
import LoadingAlert from "../../core/alert/LoadingAlert";
import AlternativeTitleCardContainerSkeleton
    from "../../core/media/card/alternate_title/AlternativeTitleCardContainerSkeleton";

export interface AlternateTitleDrawerProps {
    open: boolean
    media: MediaDetail
    onClose: () => void
}

export default function AlternateTitleDrawer({open, onClose, media}: AlternateTitleDrawerProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [titles, setMovie] = useState<AlternateTitle[]>();
    const [newTitle, setNewTitle] = useState("");

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const alternateTitleService = AlternateTitleService;

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchTitles = useCallback(async () => {

        if (open) {
            setLoading(true);
            alternateTitleService.findAllByIdTmdbAndMediaType(media.id, media.mediaType)
                .then((response => {
                    setShowServerError(false);
                    setMovie(response.data);
                }))
                .catch(onServerError)
                .finally(() => setLoading(false));
        }

    }, [open, alternateTitleService, media.id, media.mediaType]);

    const deleteAlternateTitle = useCallback(async (id: string) => {

        const result = await alternateTitleService.deleteById(id);
        if (result.status === 200) {
            fetchTitles();
        }

    }, [alternateTitleService, fetchTitles]);

    const createAlternateTitle = useCallback(async () => {

        if (newTitle) {
            const result = await alternateTitleService.create(media.id, media.mediaType, newTitle);
            if (result.status === 200) {
                setNewTitle("");
                fetchTitles();
            }
        }

    }, [alternateTitleService, fetchTitles, media.id, media.mediaType, newTitle]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchTitles();
    }

    function onTitleDelete(id: string) {
        deleteAlternateTitle(id);
    }

    const handleSearchInputChange = (change: string) => {
        setNewTitle(change);
    };

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            createAlternateTitle();
        }
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchTitles();
    }, [fetchTitles]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    const titleCpt = <Box minWidth={350} maxWidth={350} padding={2} display={"flex"} flexDirection={"column"} gap={1}>
        <TextField
            onChange={event => handleSearchInputChange(event.target.value)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            size={'small'}
            value={newTitle}
            label={"Ajouter un titre alternatif"}
        />
        <Box display={"flex"} flexDirection={"column"} gap={2}>
            {titles?.map((title) => <AlternateTitleCard key={uuidv4()} data={title} onDelete={onTitleDelete}/>)}
        </Box></Box>;

    let content;
    if (loading) {
        content = <AlternativeTitleCardContainerSkeleton/>
    } else if (showServerError || titles === undefined) {
        content = <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        content = titleCpt;
    }

    return <Drawer anchor={"right"} open={open} onClose={onClose}>
        {content}
    </Drawer>
}