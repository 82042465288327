import {Box} from "@mui/material";
import {SearchResult} from "../../../../../domain/commons/model/media/MediaSearchModels";
import MediaSearchResultCard from "../card/MediaSearchResultCard";
import {uuidv4} from "../../../../../domain/commons/utils/UUIDProvider";
import Title from "../../title/Title";
import React from "react";

export interface MediaSearchResultCardContainerProps {
    title: string,
    link?: string,
    data: SearchResult[]
}

export default function MediaSearchResultCardContainer({title, link, data}: MediaSearchResultCardContainerProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const hasData = data !== undefined && data.length > 0;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (hasData) {

        return <Box>
            <Title label={title} link={link}/>
            <Box sx={{display: 'flex', flexWrap: "wrap"}}>
                {data.map((item) => (
                    <MediaSearchResultCard data={item} key={uuidv4()}/>
                ))}
            </Box>
        </Box>
    } else {

        return <Box/>
    }
}