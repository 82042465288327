import {useEffect, useRef, useState} from "react";
import {Box, Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {YoutubeDiscoverChannel} from "../../../../../domain/commons/model/youtube/YoutubeModels";

export interface YoutubeChannelCardProps {
    data: YoutubeDiscoverChannel
}

export default function YoutubeChannelCard({data}: YoutubeChannelCardProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const componentRef = useRef<HTMLDivElement | null>(null);
    const [showDetail, setShowDetail] = useState(false);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onMouseEnter() {
        setShowDetail(true);
    }

    function onMouseLeave() {
        setShowDetail(false);
    }

    function onClickOutside(event: MouseEvent) {
        if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
            setShowDetail(false);
        }
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);

        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, []);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

        // prepare content
    const hoverContent = <Box sx={{marginTop: 5}}>
            <Typography className={"mediaCardTitle"} fontSize={20} fontWeight={900}
                        lineHeight={1}>{data.title}</Typography>
        </Box>

    let cardContent;
    if (showDetail) {
        cardContent = <Link to={"/youtube/channels/" + data.id}><CardContent
            sx={{
                position: 'absolute', bottom: 0, left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)', color: '#fff', width: '100%', height: '100%'
            }}
        >
            {hoverContent}
        </CardContent>
        </Link>
    }

    // render

    return <Card ref={componentRef}
                 sx={{borderRadius: '12px', width: 150, margin: 1, flexShrink: 0, border: '1px solid #313A47'}}
                 className={"mediaCard"}
                 onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <CardActionArea>
            <CardMedia component="img" image={data.imagePath} referrerPolicy={"no-referrer"}/>
            {cardContent}
        </CardActionArea>
    </Card>
}