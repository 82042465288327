import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {SearchResult} from "../../../../domain/commons/model/media/MediaSearchModels";
import MediaSearchService from "../../../../domain/service/media/search/MediaSearchService";
import MediaSkeletonCardContainer from "../../../component/core/media/container/skeleton/MediaSkeletonCardContainer";
import LoadingAlert from "../../../component/core/alert/LoadingAlert";
import MediaSearchResultCardContainer from "../../../component/core/media/container/MediaSearchResultCardContainer";

export default function MediaSearchPage() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [data, setData] = useState<SearchResult[]>([]);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const mediaSearchService = MediaSearchService;
    const sliderTitle = "Résultats de la recherche";

    const [searchParams] = useSearchParams();
    const pathQuery = searchParams.get("query");

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchData = useCallback(async () => {

        if (pathQuery) {
            setLoading(true);

            mediaSearchService.search(pathQuery, "LARGE", 1)
                .then((response => {
                    setShowServerError(false);
                    setData(response.data.results);
                }))
                .catch(onServerError)
                .finally(() => setLoading(false));
        }

    }, [pathQuery, mediaSearchService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {
        fetchData();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchData();
    }, [fetchData]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    let content;
    if (loading) {
        content = <MediaSkeletonCardContainer title={sliderTitle}/>
    } else if (showServerError) {
        content = <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        content = <MediaSearchResultCardContainer title={sliderTitle} data={data}/>
    }

    return <Box sx={{margin: 2}}>
        {content}
    </Box>
}