import {Box, Button} from "@mui/material";
import {TvSeasonDetail} from "../../../../../domain/commons/model/media/MediaDetailModels";
import TvSeasonHeader from "./TvSeasonHeader";
import MediaTvEpisodeCardContainer from "../../../core/media/container/MediaTvEpisodeCardContainer";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {Link} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useState} from "react";
import TvSeasonRelease from "./TvSeasonRelease";
import {TvEpisode} from "../../../../../domain/commons/model/media/MediaCoreModels";
import TvEpisodeRelease from "./TvEpisodeRelease";

type mode = 'EPISODE' | 'SEASON_RELEASE' | 'SEASON_EPISODE';

export interface TvSeasonDetailContainerProps {
    season: TvSeasonDetail
}

export default function TvSeasonDetailContainer({season}: TvSeasonDetailContainerProps) {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [mode, setMode] = useState<mode>('EPISODE');
    const [episode, setEpisode] = useState<TvEpisode>();

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    const handleSeasonReturn = () => {
        setMode("EPISODE");
    }

    const handleSeasonReleases = () => {
        setMode("SEASON_RELEASE");
    }

    const handleEpisodeReleases = (episode: TvEpisode) => {
        setEpisode(episode)
        setMode("SEASON_EPISODE");
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    let navigation;
    switch (mode) {
        case "EPISODE":
            navigation = <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Link to={`/medias/detail/tvs/${season.tv.id}`}>
                    <Button variant={"outlined"} startIcon={<ArrowBackIcon/>}>Retour à la série</Button>
                </Link>
                <Button variant={"outlined"} startIcon={<CloudDownloadIcon/>} onClick={handleSeasonReleases}>Voir les
                    releases</Button>
            </Box>;
            break;
        case "SEASON_RELEASE":
        case "SEASON_EPISODE":
            navigation = <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Button variant={"outlined"} startIcon={<ArrowBackIcon/>} onClick={handleSeasonReturn}>Retour à la saison</Button>
            </Box>;
            break;
        default:
            navigation = <Box/>;
    }

    let detail;
    switch (mode) {
        case "EPISODE":
            detail = <MediaTvEpisodeCardContainer data={season.episodes} title='Episodes' onReleaseClick={handleEpisodeReleases}/>;
            break;
        case "SEASON_RELEASE":
            detail = <TvSeasonRelease season={season}/>;
            break;
        case "SEASON_EPISODE":
            if(episode) {
                detail = <TvEpisodeRelease season={season} episode={episode}/>;
            } else {
                detail = <Box/>
            }
            break;
        default:
            detail = <Box/>;
    }

    return <Box>
        <TvSeasonHeader season={season}/>
        <Box margin={2}>
            {navigation}
            <Box margin={2}/>
            {detail}
        </Box>
    </Box>
}