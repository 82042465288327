import {Box} from "@mui/material";
import {Constants} from "../../../../../domain/commons/constants/Constants";

export interface MediaVoteProps {
    voteAverage?: number
    voteCount?: number
}

export default function MediaVote({voteAverage, voteCount}: MediaVoteProps) {

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const logoWidth = 30;
    const hasVote = voteAverage !== undefined && voteCount !== undefined && voteCount > 0;

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    if (hasVote) {
        return <Box><img alt={"tmdb"} src={Constants.assets.tmdbLogoUrl} width={logoWidth}
                         style={{marginRight: 5}}/>{Math.round(voteAverage * 10) + "%"}</Box>
    } else {

        return <Box/>
    }
}