import axios from "axios";
import {MediaRelease} from "../../../commons/model/media/MediaReleaseModels";
import {MediaType} from "../../../commons/model/media/MediaSearchModels";
import {AlternateTitle} from "../../../commons/model/download/DownloadModels";

const findAllByIdTmdbAndMediaType = (idTmdb: number, mediaType: MediaType) =>
    axios.get<AlternateTitle[]>(`/api/downloads/medias/${idTmdb}/type/${mediaType}/alternate-titles`);

const create = (idTmdb: number, mediaType: MediaType, title: string) =>
    axios.post<MediaRelease>(`/api/downloads/medias/${idTmdb}/type/${mediaType}/alternate-titles`, null,{
        params: {
            title: title
        }
    });

const update = (id: string, title: string) =>
    axios.put<MediaRelease>(`/api/downloads//alternate-titles/${id}`, null, {
        params: {
            title: title
        }
    });

const deleteById = (id: string) =>
    axios.delete<AlternateTitle[]>(`/api/downloads//alternate-titles/${id}`);

const AlternateTitleService = {
    findAllByIdTmdbAndMediaType,
    create,
    update,
    deleteById
}

export default AlternateTitleService;