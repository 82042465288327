import {Box} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {SearchResult} from "../../../../domain/commons/model/media/MediaSearchModels";
import MediaDiscoverService from "../../../../domain/service/media/discover/MediaDiscoverService";
import MediaSkeletonCardContainer from "../../../component/core/media/container/skeleton/MediaSkeletonCardContainer";
import LoadingAlert from "../../../component/core/alert/LoadingAlert";
import MediaSearchResultCardContainer from "../../../component/core/media/container/MediaSearchResultCardContainer";

export default function MediaDiscoverUpcomingMoviesPage() {

    /*******************************************************************************************************************
     * STATES
     ******************************************************************************************************************/

    const [loading, setLoading] = useState(true);
    const [showServerError, setShowServerError] = useState(false);
    const [data, setData] = useState<SearchResult[]>([]);

    /*******************************************************************************************************************
     * PARAMS
     ******************************************************************************************************************/

    const discoverService = MediaDiscoverService;
    const sliderTitle = "Films à venir";

    /*******************************************************************************************************************
     * UTILS FUNCTIONS
     ******************************************************************************************************************/

    const fetchData = useCallback(async () => {

        setLoading(true);
        discoverService.upcomingMovies("LARGE", 1)
            .then((response => {
                setShowServerError(false);
                setData(response.data.results);
            })).catch(onServerError)
            .finally(() => setLoading(false));

    }, [discoverService]);

    /*******************************************************************************************************************
     * HANDLES FUNCTIONS
     ******************************************************************************************************************/

    function onServerError() {

        setShowServerError(true);
    }

    function handleReload() {

        fetchData();
    }

    /*******************************************************************************************************************
     * INITIALIZE COMPONENT
     ******************************************************************************************************************/

    useEffect(() => {

        fetchData();
    }, [fetchData]);

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    let content;
    if (loading) {
        content = <MediaSkeletonCardContainer title={sliderTitle}/>
    } else if (showServerError) {
        content = <LoadingAlert onClick={handleReload} width={"100%"}/>
    } else {
        content = <MediaSearchResultCardContainer title={sliderTitle} data={data}/>
    }

    return <Box sx={{margin: 2}}>
        {content}
    </Box>
}