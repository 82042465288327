import {createTheme, useMediaQuery} from "@mui/material";

export const appTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#6a8ac2',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#111827',
            paper: '#1D2635'
        }
    }
});

export function useMQ() {

    const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(appTheme.breakpoints.between('sm', 'md'));
    const isLandscape = useMediaQuery('(orientation: landscape)');
    const isPermanentAppMenu = !isMobile && !isTablet && isLandscape;

    return {
        isMobile,
        isTablet,
        isLandscape,
        isPermanentAppMenu
    }
}