import {Box} from "@mui/material";
import * as React from "react";
import MediaDiscoverPopularMovies from "../../../component/media/discover/MediaDiscoverPopularMovies";
import MediaDiscoverTrendingAllByDay from "../../../component/media/discover/MediaDiscoverTrendingAllByDay";
import MediaDiscoverUpcomingMovies from "../../../component/media/discover/MediaDiscoverUpcomingMovies";
import MediaDiscoverPopularTvs from "../../../component/media/discover/MediaDiscoverPopularTvs";
import MediaDiscoverUpcomingTvs from "../../../component/media/discover/MediaDiscoverUpcomingTvs";
import MediaDiscoverRequestedMovies from "../../../component/media/discover/MediaDiscoverRequestedMovies";
import MediaDiscoverLastUpdatedTvs from "../../../component/media/discover/MediaDiscoverLastUpdatedTvs";
import MediaProviderCard from "../../../component/core/media/card/MediaProviderCard";

export default function MediaDiscoverPage() {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    return <Box sx={{margin: 2}}>

        <Box display={"flex"} flexDirection={"row"}>
            <MediaProviderCard provider={"NETFLIX"}/>
            <MediaProviderCard provider={"AMAZON_PRIME_VIDEO"}/>
            <MediaProviderCard provider={"DISNEY_PLUS"}/>
            <MediaProviderCard provider={"APPLE_TV_PLUS"}/>
        </Box>

        <MediaDiscoverTrendingAllByDay/>
        <MediaDiscoverPopularMovies/>
        <MediaDiscoverUpcomingMovies/>
        <MediaDiscoverRequestedMovies/>
        <MediaDiscoverLastUpdatedTvs/>
        <MediaDiscoverPopularTvs/>
        <MediaDiscoverUpcomingTvs/>
    </Box>
}