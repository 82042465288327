import * as React from "react";
import {Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {Constants} from "../../../../../domain/commons/constants/Constants";
import {MovieCollection} from "../../../../../domain/commons/model/media/MediaCoreModels";

export interface MediaMovieCollectionCardProps {
    collection: MovieCollection;
}

export default function MediaMovieCollectionCard({collection}: MediaMovieCollectionCardProps) {

    /*******************************************************************************************************************
     * RENDER
     ******************************************************************************************************************/

    let cardContent;
    const collectionUrl = '/medias/detail/collections/' + collection.id;
    if (collection.backdropPath) {
        cardContent = <CardContent
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
                width: '100%',
                height: '100%'
            }}
        >
            <Typography className={"mediaCardTitle"} fontSize={12} fontWeight={900}
                        lineHeight={1}>{collection.name}</Typography>
        </CardContent>
    } else {
        cardContent = <CardContent>
            <Typography className={"mediaCardTitle"} fontSize={12} fontWeight={900}
                        lineHeight={1}>{collection.name}</Typography>
        </CardContent>
    }

    return <Card
        sx={{borderRadius: '12px', width: 225, minHeight: 50, margin: 2, flexShrink: 0, border: '1px solid #313A47'}}>
        <CardActionArea>
            {collection.backdropPath && <CardMedia component="img"
                                                   image={Constants.assets.collectionBaseUrl + collection.backdropPath}/>}

            <Link to={collectionUrl} style={{textDecoration: 'none', color: 'white'}}>
                {cardContent}
            </Link>
        </CardActionArea>
    </Card>
}